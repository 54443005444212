import React, { useEffect, useState } from 'react';
import '../style.css';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Card,
  Typography,
  Radio,
  Space,
  Switch,
  message,
  Checkbox,
  Image,
} from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import Input from '../Input';
import Recoreder from './Recoreder';
import Uploader from './Uploader';
import Modal from '../Modal';
import ImageUploader from './ImageUploader';
// import University_Logo from '../../assets/George_Brown_College_logo.png';
const { Title, Text } = Typography;

const FORM_ELEMENT_TYPE = {
  TEXT: 'text',
  VOICE_RECORDING: 'voice-record-type',
};
Object.freeze(FORM_ELEMENT_TYPE);

const GenericCard = ({ title, SubHeading, Component, disabled, fileGet }) => {
  return (
    <div>
      <Card style={{ width: '80%', paddingBottom: '5%' }}>
        <div style={{ textAlign: 'center' }}>
          <Title level={4}>{title}</Title>
          <Text type="secondary" style={{ fontSize: '16px' }}>
            {SubHeading}
          </Text>
          <br />
          <Component disabled={disabled} fileGet={(file) => fileGet(file)} />
        </div>
      </Card>
    </div>
  );
};

const StudentForm = ({
  formRules,
  addField,
  addFieldLoading,
  formSubmission,
  loading,
  updateStatus,
  isEdit,
  isDisabled,
  updateValue,
  audioFileGet,
  imageGet,
  submitLabel,
}) => {
  const { properties } = formRules;
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState(1);
  const [title, setTitle] = useState('');
  const [formName, setFormName] = useState('');
  const [isAddFieldModalVisible, setIsAddFieldModalVisible] = useState(false);

  useEffect(() => {
    if (isEdit && Object.keys(formRules).length && formRules.form !== null) {
      setFormName(formRules.form.title);
    }
  }, [isEdit]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const TitleForRecorder = 'Record your name using our web audio recorder';
  const subHeadingForRecorder = '';

  const TitleForUploader = 'Upload your Names Pronunciation';
  const subHeadingForUploader = 'Note: The File Should be an .mp3';

  const ImageUploaderTitle = 'Upload your image';
  const ImageUploaderSubHeading =
    'Resolution 1920 x 1920 max size 1MB .png ,.jpg';
  const disabledFields = ['Email', 'Honours', 'Last Name'];

  function onChangeCheckbox(e) {
    setIsChecked(e.target.checked);
  }

  return (
    <div className="form-container">
      <Modal
        isVisible={isAddFieldModalVisible}
        handleOk={() => {
          addField(
            { label: title, name: title, type: 'text' },
            setIsAddFieldModalVisible(false)
          );
        }}
        submitButtonLabel={'Add'}
        handleCancel={() => setIsAddFieldModalVisible(false)}
        title="Add New Field"
        loading={addFieldLoading}
      >
        <Input
          label="ADD TITLE"
          placeholder="Add Title"
          value={title}
          onChange={setTitle}
          autoFocus
        />
      </Modal>
      <div className="form-inner-container">
        {!isDisabled && (
          <div className="form-logo-container">
            <Image width={'100%'} src={formRules.logo} />
          </div>
        )}
      </div>
      <div className="form-inner-container">
        {isDisabled && (
          <div className="form-input-container">
            <Input
              label="FORM TITLE*"
              placeholder="Form Title"
              value={formName}
              onChange={setFormName}
              autoFocus
            />
          </div>
        )}
      </div>
      {isDisabled && (
        <Divider
          style={{ width: '50%', borderTopColor: 'rgba(0, 0, 0, 0.5)' }}
        />
      )}
      <div className="form-inner-container">
        {properties &&
          properties.map((p) =>
            p.type === 'radiobutton' ? (
              p.slug === FORM_ELEMENT_TYPE.VOICE_RECORDING ? (
                <div key={p.id.toString()}>
                  <Space
                    direction="vertical"
                    className="recorder-container"
                    style={{ width: '100%', display: 'block' }}
                  >
                    <Text className="note">
                      Please audio record the pronunciation of the full name you
                      wish to be called by others.
                    </Text>

                    <div className="form-input-container">
                      <Text strong className="heading">
                        You can record your name by having the Gradline system
                        call you, or by using the Web recorder
                      </Text>
                      {/* <Switch
                    style={{ marginBottom: 5 }}
                    defaultChecked
                    onChange={onChange}
                  /> */}
                    </div>
                    <Radio.Group
                      onChange={onChange}
                      value={value}
                      disabled={isDisabled}
                    >
                      <Radio value={1} style={radioStyle}>
                        Web Recorder
                      </Radio>
                      <Radio value={2} style={radioStyle}>
                        Uploader
                      </Radio>
                    </Radio.Group>

                    {value === 1 ? (
                      <GenericCard
                        disabled={isDisabled}
                        title={TitleForRecorder}
                        SubHeading={subHeadingForRecorder}
                        Component={Recoreder}
                        fileGet={(file) => audioFileGet(p.id, file)}
                      />
                    ) : (
                      <GenericCard
                        disabled={isDisabled}
                        title={TitleForUploader}
                        SubHeading={subHeadingForUploader}
                        Component={Uploader}
                        fileGet={(file) => audioFileGet(p.id, file)}
                      />
                    )}
                  </Space>
                </div>
              ) : (
                <div key={p.id.toString()}>
                  <Space
                    direction="vertical"
                    className="recorder-container"
                    style={{ width: '100%', display: 'block' }}
                  >
                    <div className="form-input-container">
                      <Text strong className="heading">
                        Upload your profile photo here
                      </Text>
                    </div>
                    <GenericCard
                      title={ImageUploaderTitle}
                      SubHeading={ImageUploaderSubHeading}
                      Component={ImageUploader}
                      disabled={isDisabled}
                      fileGet={(file) => imageGet(p.id, file)}
                    />
                  </Space>
                </div>
              )
            ) : (
              <div key={p.id.toString()} className={`form-input-container`}>
                {!isDisabled ? (
                  p.form_attribute_university[0].status ? (
                    <Input
                      instruction={
                        p.label === 'Message' ? 'Maximum 85 characters' : ''
                      }
                      maxLength={p.label === 'Message' ? 85 : 400}
                      onChange={(v) => updateValue(p.id, v)}
                      value={p.value ? p.value : ''}
                      label={`${p.label}${p.is_required ? '*' : ''}`}
                      placeholder={p.label}
                      disabled={
                        disabledFields.includes(p.label) ? true : isDisabled
                      }
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <Input
                    onChange={(v) => updateValue(p.id, v)}
                    value={p.value ? p.value : ''}
                    label={`${p.label}${p.is_required ? '*' : ''}`}
                    placeholder={p.label}
                    disabled={
                      disabledFields.includes(p.label) ? true : isDisabled
                    }
                  ></Input>
                )}

                {!p.is_required && isDisabled && (
                  <Switch
                    checked={
                      p.form_attribute_university !== null
                        ? p.form_attribute_university[0].status
                        : false
                    }
                    style={{ marginBottom: 5 }}
                    defaultChecked
                    onChange={() => updateStatus(p.id)}
                  />
                )}
              </div>
            )
          )}
        {!isDisabled && (
          <div style={{ marginTop: 20 }}>
            <Checkbox checked={isChecked} onChange={onChangeCheckbox}>
              {`By clicking Submit, I agree that that the photo (optional) and
              message included in my registration may be used for George Brown
              College${"'"}s virtual convocation ceremony website. This site
              will be posted on the internet for up to one year after the date
              of the ceremony, following which it will be retained by the
              College and made accessible to the public as part of the
              College${"'"}s archives.`}
            </Checkbox>
          </div>
        )}
      </div>
      <Divider style={{ width: '50%', borderTopColor: 'rgba(0, 0, 0, 0.5)' }} />
      {isDisabled && (
        <div className="add-button-container">
          <div>
            <Text strong className="heading">
              Add New Field
            </Text>
            <PlusCircleFilled
              onClick={() => {
                setIsAddFieldModalVisible(true);
                setTitle('');
              }}
              className="add-field"
              style={{ fontSize: 30, color: '#70C040', marginTop: 7 }}
            />
          </div>
        </div>
      )}
      <div className="form-button-container">
        <Button
          type="primary"
          style={{
            background: '#70C040',
            borderColor: '#70C040',
            borderRadius: 5,
          }}
          loading={loading}
          onClick={() => {
            if (isDisabled) {
              formName === ''
                ? message.error('Form title is required!')
                : formSubmission({ form_title: formName, ...formRules });
            } else {
              !isChecked
                ? message.error('Please accept the Terms and Condition')
                : formSubmission();
            }
          }}
        >
          {submitLabel ? submitLabel : `Save Form Template`}
        </Button>
      </div>
    </div>
  );
};

StudentForm.propTypes = {
  formRules: PropTypes.object,
  addField: PropTypes.func,
  addFieldLoading: PropTypes.bool,
  formSubmission: PropTypes.func,
  loading: PropTypes.bool,
  updateStatus: PropTypes.func,
  isEdit: PropTypes.bool,
  isDisabled: PropTypes.bool,
  updateValue: PropTypes.func,
  audioFileGet: PropTypes.func,
  imageGet: PropTypes.func,
  submitLabel: PropTypes.string,
};

GenericCard.propTypes = {
  title: PropTypes.string,
  SubHeading: PropTypes.string,
  Component: PropTypes.func,
  disabled: PropTypes.bool,
  fileGet: PropTypes.func,
};

export default StudentForm;
