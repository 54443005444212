import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const CustomTable = ({
  columns,
  data,
  perPage = 5,
  loading = false,
  page = 1,
  IconClicked,
  total = data.length,
  setCurrentPage,
  rowSelection,
  onRow,
}) => {
  useEffect(() => {}, [data]);

  const [currentPage, setCurrentPageLocal] = useState(page);
  const [pageSize, setPageSize] = useState(perPage);
  const columnsForTable = [];

  columns.map((c, i) => {
    if (c.title === 'Actions') {
      let icons = [];
      let title = c.render
        .map((d, i) => {
          icons[i] = d.Icon;
          return '' + d.Title;
        })
        .join('/');

      columnsForTable[i] = {
        title: title,
        key: c.title.toLowerCase(),
        render: (row) => {
          return c.render.map((d, i) => {
            return (
              <d.Icon
                key={i}
                style={d.style}
                onClick={() => {
                  IconClicked(row, d.Title, dataSource);
                }}
              />
            );
          });
        },
      };
    } else {
      columnsForTable[i] = {
        title: c.title,
        dataIndex: c.targetValue,
        key: c.targetValue,
      };
    }

    return c;
  });

  const dataSource = [];
  data.map((c, i) => {
    dataSource[i] = {
      key: i + 1,
      ...data[i],
    };
    return c;
  });

  const pagination = {
    current: currentPage,
    pageSize: pageSize,
    total: total,
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setCurrentPageLocal(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const tableProps = {
    columns: columnsForTable,
    rowKey: (record) => record.key,
    dataSource: dataSource,
    pagination: pagination,
    loading: loading,
    onChange: (pagination) => handleTableChange(pagination),
  };
  if (rowSelection) {
    tableProps.rowSelection = {
      type: '',
      ...rowSelection,
    };
  }
  if (onRow) {
    tableProps.onRow = (record) => onRow(record);
  }
  return <Table {...tableProps} scroll={{ x: 'calc(500px + 50%)' }} />;
};

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  perPage: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  IconClicked: PropTypes.func,
  total: PropTypes.number,
  setCurrentPage: PropTypes.func,
  rowSelection: PropTypes.object,
  onRow: PropTypes.func,
};

export default CustomTable;
