import { DashboardOutlined, BookOutlined } from '@ant-design/icons';
export const drawerItems = {
  items: [
    {
      id: 1,
      name: 'Dashboard',
      route: '/',
      subItems: [],
      childRoute: '',
      icon: <DashboardOutlined />,
      universityRoute: true,
    },
    {
      id: 2,
      name: 'Universities',
      route: '/universities',
      subItems: [],
      childRoute: '',
      icon: <BookOutlined />,
      universityRoute: true,
    },
    {
      id: 3,
      name: 'Student Dashboard',
      route: '/student-dashboard',
      subItems: [],
      childRoute: '',
      icon: <BookOutlined />,
      universityRoute: false,
    },
    {
      id: 4,
      name: 'Form Templates',
      route: '/form-template',
      subItems: [],
      childRoute: '',
      icon: <BookOutlined />,
      universityRoute: false,
    },
    {
      id: 5,
      name: 'Slides',
      route: '/slides',
      subItems: [],
      childRoute: '',
      icon: <BookOutlined />,
      universityRoute: false,
    },
  ],
};
