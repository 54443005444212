import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Image, Checkbox, Tooltip, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const StudentsReviewItem = ({
  student,
  selected,
  changeStudentSelection,
  approveStudent,
  rejectStudent,
}) => {
  const name = [
    student.first_name,
    student.middle_name,
    student.last_name,
  ].join(' ');

  const handleChange = (e) => {
    if (changeStudentSelection) {
      changeStudentSelection(student.id, e.target.checked);
    }
  };

  const handleClickApprove = () => {
    if (approveStudent) {
      approveStudent(student.id);
    }
  };

  const handleClickReject = () => {
    if (rejectStudent) {
      rejectStudent(student.id);
    }
  };

  return (
    <div className="h-100">
      <Badge.Ribbon
        text={
          student.status === 3
            ? 'Approved'
            : student.status === 4
            ? 'Rejected'
            : 'Submitted'
        }
        color={
          student.status === 3
            ? '#52c41a'
            : student.status === 4
            ? '#f5222d'
            : '#7d7d7d'
        }
      >
        <div className="border rounded p-2 h-100 pt-4 d-flex flex-column align-items-center justify-content-between">
          <div className="d-flex align-self-stretch">
            <div className="mr-2">
              <Checkbox checked={selected} onChange={handleChange} />
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center py-1">
              <div className="w-100">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: 120 }}
                >
                  {student.presentation && student.presentation.profile_pic ? (
                    <Image
                      src={student.presentation.profile_pic}
                      alt={name}
                      height={120}
                      style={{ objectFit: 'contain' }}
                    />
                  ) : (
                    <UserOutlined style={{ color: '#7f7f7f', fontSize: 64 }} />
                  )}
                </div>
                <Title level={5}>{name}</Title>
                <Paragraph>{student.message}</Paragraph>
                {student.presentation && student.presentation.audio_link && (
                  <audio controls style={{ width: 160 }}>
                    <source src={student.presentation.audio_link} />
                  </audio>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="mr-2">
              <Tooltip title="approve">
                <Button
                  icon={<CheckOutlined style={{ color: '#52c41a' }} />}
                  shape="circle"
                  onClick={handleClickApprove}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </Tooltip>
            </div>
            <Tooltip title="reject">
              <Button
                icon={<CloseOutlined style={{ color: '#ff4d4f' }} />}
                shape="circle"
                onClick={handleClickReject}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Badge.Ribbon>
    </div>
  );
};

StudentsReviewItem.propTypes = {
  student: PropTypes.object,
  selected: PropTypes.bool,
  changeStudentSelection: PropTypes.func,
  approveStudent: PropTypes.func,
  rejectStudent: PropTypes.func,
};

export default StudentsReviewItem;
