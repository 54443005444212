import React from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';

const CustomModal = ({
  children,
  isVisible,
  handleOk,
  submitButtonLabel,
  cancelButtonLabel,
  handleCancel,
  danger,
  title,
  loading,
}) => {
  return (
    <Modal
      centered
      visible={isVisible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          style={{
            borderRadius: 5,
          }}
        >
          {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
        </Button>,
        <Button
          key="submit"
          type={'primary'}
          loading={loading}
          onClick={handleOk}
          style={{
            background: danger ? 'red' : '#70C040',
            borderColor: danger ? 'red' : '#70C040',
            borderRadius: 5,
          }}
        >
          {submitButtonLabel}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  children: PropTypes.element,
  submitButtonLabel: PropTypes.string,
  loading: PropTypes.bool,
  cancelButtonLabel: PropTypes.string,
  danger: PropTypes.bool,
};

export default CustomModal;
