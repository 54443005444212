import React from 'react';
import { Row, Col, Typography, Image } from 'antd';
// import RecorderComponent from './RecorderComponent';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const StudentDetail = ({ student, loading }) => {
  const { Title } = Typography;

  const handleClick = () => {
    if (student.form_link === null) {
      return;
    }
    window.open(student.form_link, '_blank');
  };

  // const getUrlByFormAttId = (attr_id) => {
  //   // eslint-disable-next-line no-empty
  //   if (student.form_values != null) {
  //     const result = student.form_values.filter(
  //       (fv) => fv.form_attribute_id == attr_id
  //     );
  //     if (result != null) {
  //       console.log(
  //         process.env.REACT_APP_BACKEND + '/storage/' + result[0].url
  //       );
  //       return process.env.REACT_APP_BACKEND + '/storage/' + result[0].url;
  //     }
  //   }
  //   console.log('nothing');
  //   return '';
  // };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '5%',
        flexDirection: 'column',
      }}
      className="h-100"
    >
      {loading ? (
        <LoadingOutlined style={{ fontSize: 30 }} />
      ) : (
        <>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Col className="gutter-row" span={24}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Title level={5}>Student Id: {student.id}</Title>
                <Title level={5}>
                  Name:{' '}
                  {`${student.first_name} ${student.middle_name ?? ''} ${
                    student.last_name
                  }`}
                </Title>
                <Title level={5}>Email: {student.email}</Title>
                <Title level={5}>Message: {student.message}</Title>
                <Title level={5}>
                  Approval Status:&nbsp;
                  {student.status === 3
                    ? 'Approved'
                    : student.status === 4
                    ? 'Rejected'
                    : 'Submitted'}
                </Title>
                <Title level={5}>
                  FormLink:{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    onClick={handleClick}
                  >
                    {student.form_link === null
                      ? 'N/A'
                      : `${student.form_link}`}
                  </a>
                </Title>
              </div>
            </Col>
          </Row>
          {student.presentation && (
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '5%',
              }}
            >
              <Col className="gutter-row" span={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <audio
                    controls
                    src={student.presentation.audio_link}
                    preload="auto"
                    type="audio/mpeg"
                  >
                    <source
                      type="audio/mp3"
                      src={student.presentation.audio_link}
                    />
                  </audio>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Image
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        borderRadius: '3px',
                        height: '170px',
                        width: '100%',
                      }}
                      src={student.presentation.profile_pic}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

StudentDetail.propTypes = {
  student: PropTypes.object,
  loading: PropTypes.bool,
};

export default StudentDetail;
