import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UniversityTable from './components/UniversityTable';
import UniversityCreationForm from './components/UniversityCreationForm';
import { useHistory } from 'react-router-dom';
import './style.css';
import {
  addField,
  getUniversityList,
  createUniversity,
  formSubmission,
  updateStatus,
  importStudent,
  getUniversity,
  updateName,
  deleteStudent,
  getTemplates,
  selectSlide,
  deleteUniversity,
  createAdmin,
} from './actions';

import { IS_UNIVERTIY_CREATE, SELECT_UNIVERSITY } from '../Layout/constants';
import { RESET_STATE } from './constants';
import Layout from '../Layout';
const LIMIT = 10;
const University = ({
  isUniversityCreation,
  createUni,
  formRules,
  loading,
  addField,
  addFieldLoading,
  formSubmissionAction,
  updateStatusAction,
  importStudents,
  students,
  getUniversities,
  universities,
  universityCreation,
  resetState,
  total,
  getUni,
  updateUniName,
  deleteStudentAction,
  selectUniversity,
  getTemplatesAction,
  templates,
  selectSlideAction,
  templateId,
  templateLoading,
  deleteUniversityAction,
  deleteLoading,
  universityAdmins,
  createAdminAction,
  adminCreationLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    getUniversities(currentPage, LIMIT);
  }, [currentPage]);
  return (
    <Layout>
      <div>
        {isUniversityCreation ? (
          <UniversityCreationForm
            adminCreationLoading={adminCreationLoading}
            createAdmin={(data, callBack) => createAdminAction(data, callBack)}
            universityAdmins={universityAdmins}
            templateLoading={templateLoading}
            getTemplates={() => getTemplatesAction()}
            universityCreation={() => {
              getUniversities(currentPage, LIMIT);
              universityCreation();
            }}
            updateStatus={(id) => updateStatusAction(id)}
            updateName={(data, id, callBack) =>
              updateUniName(data, id, callBack)
            }
            createUniversity={(data, callBack) => createUni(data, callBack)}
            formRules={formRules}
            loading={loading}
            templateId={templateId}
            addField={(data, callBack) => {
              addField(data, callBack);
            }}
            addFieldLoading={addFieldLoading}
            formSubmission={(data, callBack) =>
              formSubmissionAction(data, callBack)
            }
            importStudents={(data, callBack) => importStudents(data, callBack)}
            students={students}
            deleteStudent={(id) => deleteStudentAction(id)}
            templates={templates}
            selectSlide={(data) => selectSlideAction(data)}
          />
        ) : (
          <UniversityTable
            deleteLoading={deleteLoading}
            deleteUniversity={(id, callBack) =>
              deleteUniversityAction(id, callBack)
            }
            getUniversity={(id) => {
              getUni(id, () => universityCreation());
            }}
            data={universities}
            loading={loading}
            total={total}
            selectUniversity={(id, name) => {
              history.push(`/university/${name}`);
              selectUniversity(id, name);
            }}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </Layout>
  );
};

University.propTypes = {
  getUniversities: PropTypes.func,
  isUniversityCreation: PropTypes.bool,
  createUni: PropTypes.func,
  formRules: PropTypes.object,
  loading: PropTypes.bool,
  addField: PropTypes.func,
  addFieldLoading: PropTypes.bool,
  formSubmissionAction: PropTypes.func,
  updateStatusAction: PropTypes.func,
  importStudents: PropTypes.func,
  students: PropTypes.array,
  universities: PropTypes.array,
  universityCreation: PropTypes.func,
  resetState: PropTypes.func,
  total: PropTypes.number,
  getUni: PropTypes.func,
  updateUniName: PropTypes.func,
  selectUniversity: PropTypes.func,
  deleteStudentAction: PropTypes.func,
  deleteUniversityAction: PropTypes.func,
  getTemplatesAction: PropTypes.func,
  templates: PropTypes.array,
  selectSlideAction: PropTypes.func,
  templateId: PropTypes.number,
  templateLoading: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  createAdminAction: PropTypes.func,
  universityAdmins: PropTypes.array,
  adminCreationLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { isUniversityCreation } = state.layoutState;
  const {
    formRules,
    loading,
    addFieldLoading,
    students,
    universityAdmins,
    universities,
    total,
    templates,
    templateId,
    templateLoading,
    deleteLoading,
    adminCreationLoading,
  } = state.universityState;
  return {
    isUniversityCreation,
    formRules,
    loading,
    addFieldLoading,
    universityAdmins,
    students,
    universities,
    total,
    templates,
    templateId,
    templateLoading,
    deleteLoading,
    adminCreationLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getUni: (id, callBack) => {
    dispatch(getUniversity(id, callBack));
  },
  getUniversities: (currentPage, limit) => {
    dispatch(getUniversityList(currentPage, limit));
  },
  createUni: (data, callback) => {
    dispatch(createUniversity(data, callback));
  },
  addField: (data, callback) => {
    dispatch(addField(data, callback));
  },
  formSubmissionAction: (data, callback) => {
    dispatch(formSubmission(data, callback));
  },
  updateStatusAction: (id) => {
    dispatch(updateStatus(id));
  },
  importStudents: (data, callback) => {
    dispatch(importStudent(data, callback));
  },
  universityCreation: () => {
    dispatch({
      type: IS_UNIVERTIY_CREATE,
    });
  },
  updateUniName: (data, id, callback) => {
    dispatch(updateName(data, id, callback));
  },
  resetState: () => {
    dispatch({
      type: RESET_STATE,
    });
  },
  getTemplatesAction: () => {
    dispatch(getTemplates());
  },
  selectSlideAction: (data) => {
    dispatch(selectSlide(data));
  },
  deleteStudentAction: (id) => {
    dispatch(deleteStudent(id));
  },
  deleteUniversityAction: (id, callBack) => {
    dispatch(deleteUniversity(id, callBack));
  },
  createAdminAction: (data, callback) => {
    dispatch(createAdmin(data, callback));
  },
  selectUniversity: (id, name) => {
    dispatch({
      type: SELECT_UNIVERSITY,
      id: id,
      name: name,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(University);
