import React from 'react';
import LoginForm from './components/LoginForm';
import './style.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Login } from './actions';

const Auth = ({ Login, state }) => {
  return <LoginForm login={Login} state={state} />;
};

Auth.propTypes = {
  state: PropTypes.object,
  Login: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    state: state.AuthState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  Login: (userinfo) => {
    dispatch(Login(userinfo));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
