import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
const ProtectdRoute = ({ Component, rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem('token') !== null) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: '/login' }} />;
        }
      }}
    />
  );
};

ProtectdRoute.propTypes = {
  rest: PropTypes.any,
  Component: PropTypes.any,
};

export default ProtectdRoute;
