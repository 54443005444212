import React from 'react';
import PropTypes from 'prop-types';
import { Input, Typography } from 'antd';
import './style.css';
const { Text } = Typography;

const CusotmInput = ({
  placeholder,
  label,
  value,
  onChange,
  disabled,
  autoFocus,
  maxLength,
  instruction,
}) => {
  return (
    <div className="input-container">
      <Text strong>{label}</Text>
      {instruction && instruction !== '' && (
        <Text type="secondary">{` (${instruction})`}</Text>
      )}
      <Input
        maxLength={maxLength ? maxLength : 500}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

CusotmInput.propTypes = {
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  instruction: PropTypes.string,
};

export default CusotmInput;
