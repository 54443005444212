// ./src/reducers/index.js

import { combineReducers } from 'redux';
import dashboardRedcuer from '../containers/Dashboard/reducer';
import universityReducer from '../containers/University/reducer';
import layoutReducer from '../containers/Layout/reducer';
import AuthReducer from '../containers/Authentication/reducer';
import StudentReducer from '../containers/Students/reducer';
import StundetFormReducer from '../containers/StudentForm/reducer';

export default combineReducers({
  dashboardState: dashboardRedcuer,
  layoutState: layoutReducer,
  universityState: universityReducer,
  AuthState: AuthReducer,
  studentState: StudentReducer,
  studentFormState: StundetFormReducer,
});
