export const GET_STUDENT_INFO = 'STUDENT_DASHBOARD@GET_STUDENT_INFO';
export const GET_STUDENT_INFO_INIT = 'STUDENT_DASHBOARD@GET_STUDENT_INFO_INIT';
export const GET_STUDENT_INFO_SUCCESS =
  'STUDENT_DASHBOARD@GET_STUDENT_INFO_SUCCESS';
export const GET_STUDENT_INFO_FAILED =
  'STUDENT_DASHBOARD@GET_STUDENT_INFO_FAILED';

export const GET_STUDNETS = 'STUDENT_DASHBOARD@GET_STUDNETS';
export const GET_STUDNETS_INIT = 'STUDENT_DASHBOARD@GET_STUDNETS_INIT';
export const GET_STUDNETS_SUCCESS = 'STUDENT_DASHBOARD@GET_STUDNETS_SUCCESS';
export const GET_STUDNETS_FAILED = 'STUDENT_DASHBOARD@GET_STUDNETS_FAILED';

export const DOWNLOAD_PRESENTATION = 'STUDENT_DASHBOARD@DOWNLOAD_PRESENTATION';
export const DOWNLOAD_PRESENTATION_INIT =
  'STUDENT_DASHBOARD@DOWNLOAD_PRESENTATION_INIT';
export const DOWNLOAD_PRESENTATION_SUCCESS =
  'STUDENT_DASHBOARD@DOWNLOAD_PRESENTATION_SUCCESS';
export const DOWNLOAD_PRESENTATION_FAILED =
  'STUDENT_DASHBOARD@DOWNLOAD_PRESENTATION_FAILED';

export const UPDATE_SELECTED_STUDENTS =
  'STUDENT_DASHBOARD@UPDATE_SELECTED_STUDENTS';

export const SEND_EMAIL_TO_SELECTED_STUDENTS =
  'STUDENT_DASHBOARD@SEND_EMAIL_TO_SELECTED_STUDENTS';
export const SEND_EMAIL_TO_SELECTED_STUDENTS_DONE =
  'STUDENT_DASHBOARD@SEND_EMAIL_TO_SELECTED_STUDENTS_DONE';

export const STUDENTS_TYPES = {
  RECORDED: 'recorded',
  UNRECORDED: 'unrecorded',
  REQUESTD: 'requested',
};

export const APPROVE_STUDENT = 'STUDENT_DASHBOARD@APPROVE_STUDENT';
export const REJECT_STUDENT = 'STUDENT_DASHBOARD@REJECT_STUDENT';
