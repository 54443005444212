import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import App from '../src/containers/App';
import AppReducers from './core/store';
import rootSaga from './core/saga';

import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(AppReducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
LogRocket.init('5qcee8/gradline');

render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
