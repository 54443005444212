import {
  CREATE_UNIVERSITY,
  CREATE_UNIVERSITY_INIT,
  CREATE_UNIVERSITY_SUCCESS,
  CREATE_UNIVERSITY_FAILED,
  ADD_NEW_FIELD,
  ADD_NEW_FIELD_INIT,
  ADD_NEW_FIELD_SUCCESS,
  ADD_NEW_FIELD_FAILED,
  FORM_SUBMISSION_INIT,
  FORM_SUBMISSION_SUCCESS,
  FORM_SUBMISSION_FAILED,
  FORM_SUBMISSION,
  UPDATE_STATUS,
  IMPORT_LIST,
  IMPORT_LIST_INIT,
  IMPORT_LIST_FAILED,
  IMPORT_LIST_SUCCESS,
  GET_UNIVERSITY_LIST,
  GET_UNIVERSITY_LIST_INIT,
  GET_UNIVERSITY_LIST_SUCCESS,
  GET_UNIVERSITY_LIST_FAILED,
  GET_UNIVERSITY,
  GET_UNIVERSITY_INIT,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_FAILED,
  UPDATE_NAME,
  UPDATE_NAME_INIT,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILED,
  DELETE_STUDENT,
  DELETE_STUDENT_FAILED,
  DELETE_STUDENT_INIT,
  DELETE_STUDENT_SUCCESS,
  GET_TEMPLATES,
  GET_TEMPLATES_INIT,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  SELECT_SLIDE,
  SELECT_SLIDE_INIT,
  SELECT_SLIDE_SUCCESS,
  SELECT_SLIDE_FAILED,
  DELETE_UNIVERSITY,
  DELETE_UNIVERSITY_INIT,
  DELETE_UNIVERSITY_SUCCESS,
  DELETE_UNIVERSITY_FAILED,
  CREATE_ADMIN,
  CREATE_ADMIN_INIT,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
} from './constants';

//Get University
export function getUniversity(id, callBack) {
  return {
    type: GET_UNIVERSITY,
    id,
    callBack,
  };
}
export function getUniversityInit() {
  return {
    type: GET_UNIVERSITY_INIT,
  };
}
export function getUniversitySuccess(data) {
  return {
    type: GET_UNIVERSITY_SUCCESS,
    data,
  };
}
export function getUniversityFailed(error) {
  return {
    type: GET_UNIVERSITY_FAILED,
    error,
  };
}

//Get Universities List
export function getUniversityList(currentPage, limit) {
  return {
    type: GET_UNIVERSITY_LIST,
    currentPage,
    limit,
  };
}
export function getUniversityListInit() {
  return {
    type: GET_UNIVERSITY_LIST_INIT,
  };
}
export function getUniversityListSuccess(data) {
  return {
    type: GET_UNIVERSITY_LIST_SUCCESS,
    data,
  };
}
export function getUniversityListFailed(error) {
  return {
    type: GET_UNIVERSITY_LIST_FAILED,
    error,
  };
}

//Get Templates
export function getTemplates() {
  return {
    type: GET_TEMPLATES,
  };
}
export function getTemplatesInitiate() {
  return {
    type: GET_TEMPLATES_INIT,
  };
}
export function getTemplatesSuccess(data) {
  return {
    type: GET_TEMPLATES_SUCCESS,
    data,
  };
}
export function getTemplatesFailed(error) {
  return {
    type: GET_TEMPLATES_FAILED,
    error,
  };
}

//SELECT Slide
export function selectSlide(data) {
  return {
    type: SELECT_SLIDE,
    data: data,
  };
}
export function selectSlideInit() {
  return {
    type: SELECT_SLIDE_INIT,
  };
}
export function selectSlideSuccess(id) {
  return {
    type: SELECT_SLIDE_SUCCESS,
    id,
  };
}
export function selectSlideFailed(error) {
  return {
    type: SELECT_SLIDE_FAILED,
    error,
  };
}

//University creation
export function createUniversity(data, callBack) {
  return {
    type: CREATE_UNIVERSITY,
    data,
    callBack,
  };
}
export function createUniversityInitiate() {
  return {
    type: CREATE_UNIVERSITY_INIT,
  };
}
export function createUniversitySuccess(data) {
  return {
    type: CREATE_UNIVERSITY_SUCCESS,
    data,
  };
}
export function createUniversityFailed(error) {
  return {
    type: CREATE_UNIVERSITY_FAILED,
    error,
  };
}

// Add new field
export function addField(data, callBack) {
  return {
    type: ADD_NEW_FIELD,
    data,
    callBack,
  };
}
export function addFieldInitiate() {
  return {
    type: ADD_NEW_FIELD_INIT,
  };
}
export function addFieldSuccess(data) {
  return {
    type: ADD_NEW_FIELD_SUCCESS,
    data,
  };
}
export function addFieldFailed(error) {
  return {
    type: ADD_NEW_FIELD_FAILED,
    error,
  };
}

// Form submission
export function formSubmission(data, callBack) {
  return {
    type: FORM_SUBMISSION,
    data,
    callBack,
  };
}
export function formSubmissionInitiate() {
  return {
    type: FORM_SUBMISSION_INIT,
  };
}
export function formSubmissionSuccess(data, name) {
  return {
    type: FORM_SUBMISSION_SUCCESS,
    data,
    name,
  };
}
export function formSubmissionFailed(error) {
  return {
    type: FORM_SUBMISSION_FAILED,
    error,
  };
}

export function updateStatus(id) {
  return {
    type: UPDATE_STATUS,
    id,
  };
}

//Import Students
export function importStudent(data, callBack) {
  return {
    type: IMPORT_LIST,
    data,
    callBack,
  };
}
export function importStudentInitiate() {
  return {
    type: IMPORT_LIST_INIT,
  };
}
export function importStudentSuccess(data) {
  return {
    type: IMPORT_LIST_SUCCESS,
    data,
  };
}
export function importStudentFailed(error) {
  return {
    type: IMPORT_LIST_FAILED,
    error,
  };
}

//Update Name
export function updateName(data, id, callBack) {
  return {
    type: UPDATE_NAME,
    data,
    id,
    callBack,
  };
}
export function updateNameInitiate() {
  return {
    type: UPDATE_NAME_INIT,
  };
}
export function updateNameSuccess(data) {
  return {
    type: UPDATE_NAME_SUCCESS,
    data,
  };
}
export function updateNameFailed(error) {
  return {
    type: UPDATE_NAME_FAILED,
    error,
  };
}

//Delete University
export function deleteUniversity(id, callBack) {
  return {
    type: DELETE_UNIVERSITY,
    id,
    callBack,
  };
}
export function deleteUniversityInitiate() {
  return {
    type: DELETE_UNIVERSITY_INIT,
  };
}
export function deleteUniversitySuccess(id) {
  return {
    type: DELETE_UNIVERSITY_SUCCESS,
    id,
  };
}
export function deleteUniversityFailed(error) {
  return {
    type: DELETE_UNIVERSITY_FAILED,
    error,
  };
}

//Delete Student
export function deleteStudent(id, callBack) {
  return {
    type: DELETE_STUDENT,
    id,
    callBack,
  };
}
export function deleteStudentIniate() {
  return {
    type: DELETE_STUDENT_INIT,
  };
}
export function deleteStudentSuccess(id) {
  return {
    type: DELETE_STUDENT_SUCCESS,
    id,
  };
}
export function deleteStudentFailed(error) {
  return {
    type: DELETE_STUDENT_FAILED,
    error,
  };
}

//Create Admin
export function createAdmin(data, callback) {
  return {
    type: CREATE_ADMIN,
    data,
    callback,
  };
}
export function createAdminInit() {
  return {
    type: CREATE_ADMIN_INIT,
  };
}
export function createAdminSuccess(data) {
  return {
    type: CREATE_ADMIN_SUCCESS,
    data,
  };
}
export function createAdminFailed(error) {
  return {
    type: CREATE_ADMIN_FAILED,
    error,
  };
}
