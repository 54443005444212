import { all } from 'redux-saga/effects';
import { universitySaga } from '../containers/University/saga';
import { LoginSaga } from '../containers/Authentication/saga';
import { StudentSaga } from '../containers/Students/saga';
import { StudentFormSaga } from '../containers/StudentForm/saga';
export default function* rootSaga() {
  yield all([
    ...universitySaga,
    ...LoginSaga,
    ...StudentSaga,
    ...StudentFormSaga,
  ]);
}
