import {
  SELECTE_ROUTE,
  IS_UNIVERTIY_CREATE,
  CHANGE_STUDENT_DETAIL_NAV,
  SELECT_UNIVERSITY,
} from './constants';

const initialState = {
  route: 'Dashboard',
  isUniversityCreation: true,
  insideStudentDetail: false,
  selectedUniversityId: null,
  selectedUniversityName: null,
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case SELECTE_ROUTE:
      return {
        ...state,
        route: action.route,
        isUniversityCreation: false,
      };
    case IS_UNIVERTIY_CREATE:
      return {
        ...state,
        isUniversityCreation: !state.isUniversityCreation,
      };
    case CHANGE_STUDENT_DETAIL_NAV:
      return {
        ...state,
        insideStudentDetail: !state.insideStudentDetail,
      };
    case SELECT_UNIVERSITY: {
      return {
        ...state,
        selectedUniversityId: action.id,
        selectedUniversityName: action.name,
      };
    }
    default:
      return state;
  }
}
