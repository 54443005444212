import {
  LOGIN,
  LOGINSUCCESS,
  LOGINFAILURE,
  EMAILCONFIRMATION,
  RESETPASSWORD,
} from './constants';

export function Login(payload) {
  return {
    type: LOGIN,
    payload,
  };
}

export function LoginSuccess(payload) {
  return {
    type: LOGINSUCCESS,
    payload,
  };
}

export function LoginFailure() {
  return {
    type: LOGINFAILURE,
  };
}

export function EmailConfirmationAction(payload) {
  return {
    type: EMAILCONFIRMATION,
    payload,
  };
}

export function ResetPasswordAction(payload) {
  return {
    type: RESETPASSWORD,
    payload,
  };
}
