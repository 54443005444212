import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu, Card, Typography, Button, Switch, Popover } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './style.css';
import {
  AlignRightOutlined,
  AlignLeftOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getUniversityList } from '../University/actions';
import logo from '../../assets/logo-header.png';
import { drawerItems } from './DrawerItems';
import dashboardIcon from '../../assets/menu-icon.png';
import { NavLink } from 'react-router-dom';
import { Dropdown } from '../../components';
import {
  IS_UNIVERTIY_CREATE,
  SELECTE_ROUTE,
  CHANGE_STUDENT_DETAIL_NAV,
  SELECT_UNIVERSITY,
} from './constants';
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const WithLayout = ({
  children,
  state,
  setPath,
  universityCreation,
  navStudentDetail,
  getUniversities,
  universities,
  selectUniversity,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let pathName = '';
    if (location.pathname.includes('/form')) {
      pathName = '/form-template';
    } else if (location.pathname.includes('/slides')) {
      pathName = '/slides';
    } else if (location.pathname.includes('university')) {
      pathName = '/student-dashboard';
    }
    const path = drawerItems.items.filter(
      (d) => d.route === (pathName === '' ? location.pathname : pathName)
    )[0];
    setPath(path);
    setCurrent(path.id - 1);
  }, []);

  const unversityCreation = () => {
    universityCreation();
  };

  const getRoute = (route) => {
    if (route === '/student-dashboard') {
      return `/university/${state.selectedUniversityName}`;
    } else if (route === '/form-template') {
      return `/${state.selectedUniversityName}/form`;
    } else if (route === '/slides') {
      return `/${state.selectedUniversityName}/slides`;
    } else {
      return route;
    }
  };

  const renderMenu = () => {
    if (state.selectedUniversityId !== null) {
      return drawerItems.items.map(
        ({ name, route, icon, universityRoute }, index) => {
          if (!universityRoute) {
            return (
              <Menu.Item key={index} icon={icon}>
                <NavLink to={getRoute(route)}>{name}</NavLink>
              </Menu.Item>
            );
          }
        }
      );
    } else {
      return drawerItems.items.map(
        ({ name, route, icon, universityRoute }, index) => {
          if (universityRoute) {
            return (
              <Menu.Item key={index} icon={icon}>
                <NavLink to={route}>{name}</NavLink>
              </Menu.Item>
            );
          }
        }
      );
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsed={collapsed} className="slider-cotaniner">
        {!collapsed ? (
          <div className="logo-container">
            <img
              src={logo}
              alt="section-img"
              style={{ width: '150px', margin: '20px' }}
            />
            <AlignRightOutlined
              style={{ color: '#fff' }}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        ) : (
          <div className="logo-container">
            <AlignLeftOutlined
              style={{ color: '#fff' }}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
        )}
        <Menu
          theme="dark"
          defaultSelectedKeys={['0']}
          onSelect={(data) => {
            setCurrent(data.key);
            setPath(drawerItems.items[data.key]);
          }}
          selectedKeys={[current.toString()]}
          mode="inline"
        >
          {renderMenu()}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0 }}>
          <Card className="header-container">
            {state.selectedUniversityId !== null && (
              <Dropdown
                selected={state.selectedUniversityId}
                handleSelected={(id) => {
                  var selectedUni = universities.filter((u) => u.id === id)[0];
                  history.push(`/university/${selectedUni.slug}`);
                  selectUniversity(selectedUni.id, selectedUni.slug);
                }}
                data={universities}
              />
            )}
            <div className="user-container">
              {state.selectedUniversityId !== null && (
                <Switch
                  checked={state.selectedUniversityId !== null}
                  style={{ marginTop: 5, marginRight: 10 }}
                  defaultChecked
                  onChange={() => {
                    history.push(`/universities`);
                    selectUniversity(null, null);
                  }}
                />
              )}
              <Popover
                placement="bottomRight"
                title={<a onClick={() => {}}>Profile</a>}
                content={
                  <a
                    onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('user');
                      history.push('/login');
                    }}
                  >
                    Logout
                  </a>
                }
                trigger="hover"
              >
                <UserOutlined style={{ fontSize: 28, color: '#365680' }} />
              </Popover>
            </div>
          </Card>
          <Card className="heading-container">
            <img
              src={dashboardIcon}
              alt="dashboard-img"
              style={{ marginTop: -15, marginRight: 15, width: 50 }}
            />
            <Title level={4} style={{ width: '100%' }}>
              {state.route}
            </Title>
            {state.route === 'Universities' && (
              <div className="user-container">
                {state.isUniversityCreation ? (
                  <Button
                    onClick={() => {
                      getUniversities(1, 10);
                      unversityCreation();
                    }}
                  >
                    BACK
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    style={{ background: '#70C040', borderColor: '#70C040' }}
                    onClick={() => {
                      unversityCreation();
                    }}
                  >
                    CREATE NEW
                  </Button>
                )}
              </div>
            )}
            {state.route === 'Student Dashboard' && state.insideStudentDetail && (
              <div className="user-container">
                <Button
                  onClick={() => {
                    navStudentDetail();
                  }}
                >
                  BACK
                </Button>
              </div>
            )}
          </Card>
        </Header>
        <Content style={{ margin: '160px 16px 0px' }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

WithLayout.propTypes = {
  children: PropTypes.any,
  state: PropTypes.any,
  setPath: PropTypes.func,
  universityCreation: PropTypes.func,
  navStudentDetail: PropTypes.func,
  getUniversities: PropTypes.func,
  universities: PropTypes.array,
  selectUniversity: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { universities } = state.universityState;
  return {
    state: state.layoutState,
    universities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setPath: (path) => {
    dispatch({
      type: SELECTE_ROUTE,
      route: path.name,
    });
  },
  selectUniversity: (id, name) => {
    dispatch({
      type: SELECT_UNIVERSITY,
      id: id,
      name: name,
    });
  },
  universityCreation: () => {
    dispatch({
      type: IS_UNIVERTIY_CREATE,
    });
  },
  navStudentDetail: () => {
    dispatch({
      type: CHANGE_STUDENT_DETAIL_NAV,
    });
  },
  getUniversities: (currentPage, limit) => {
    dispatch(getUniversityList(currentPage, limit));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithLayout);
