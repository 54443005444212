import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import CusotmInput from '../../../components/Input';
import authImage from '../../../assets/signup.png';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EmailConfirmationAction } from '../actions';

const EmailConfirmation = ({ EmailConfirmationFunc, state }) => {
  const history = useHistory();
  const { Title } = Typography;
  const [email, setEmail] = useState('');

  const InputChangeEmail = (emailValue) => {
    setEmail(emailValue);
  };

  const HandleResetPassword = () => {
    EmailConfirmationFunc(email);
  };

  useEffect(() => {
    if (
      state.emailConfirmationStarted === false &&
      state.redirectToResetPassword === true
    ) {
      history.push('/reset-password');
    } else if (state.emailConfirmationStarted === true) {
      console.log('waiting for response');
    }
  }, [state]);

  return (
    <div>
      <Row className="auth-container">
        <Col span={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <img src={logo} className="logo-img" />
            <img src={authImage} className="auth-img" />
          </div>
        </Col>
        <Col span={12} style={{ paddingRight: '5%' }}>
          <Title level={3}>FORGOT YOUR PASSWORD? </Title>
          <Title level={5}>
            Confirm your email and we&apos;ll send the instructions{' '}
          </Title>
          <CusotmInput
            placeholder="Type"
            label="Email"
            value={email}
            onChange={InputChangeEmail}
          />
          <Button
            type="primary"
            size={'20px'}
            style={{
              marginTop: '20px',
            }}
            onClick={HandleResetPassword}
          >
            RESET PASSWORD
          </Button>
        </Col>
      </Row>
    </div>
  );
};

EmailConfirmation.propTypes = {
  state: PropTypes.object,
  EmailConfirmationFunc: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    state: state.AuthState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  EmailConfirmationFunc: (email) => {
    dispatch(EmailConfirmationAction(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
