import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Modal } from '../../../components';
import { Typography } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';
import '../style.css';
const { Text } = Typography;
const UniversityTable = ({
  data,
  loading,
  total,
  setCurrentPage,
  getUniversity,
  selectUniversity,
  deleteUniversity,
  deleteLoading,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedUni, setSelectedUni] = useState(null);
  const IconClicked = (row, type, dataSource = []) => {
    let index = dataSource.findIndex((c) => c.key === row.key);
    switch (type) {
      case 'View':
        {
          selectUniversity(data[index].id, data[index].slug);
        }
        break;
      case 'Edit':
        {
          getUniversity(data[index].id);
        }
        break;
      case 'Delete':
        {
          setSelectedUni(data[index].id);
          setIsVisible(true);
        }
        break;
      default:
        console.log('error');
    }
  };
  const columns = [
    {
      title: 'Name',
      targetValue: 'name',
    },
    {
      title: 'Students',
      targetValue: 'students_count',
    },
    {
      title: 'Status',
      targetValue: 'status',
    },
    {
      title: 'Date Created',
      targetValue: 'created_at',
    },
    {
      title: 'Actions',
      targetValue: '',
      render: [
        {
          Title: 'View',
          Icon: EyeOutlined,
          style: {
            fontSize: '16px',
            margin: '0px 10px 0px 0px',
            ':hover': { fontSize: '18px' },
          },
        },
        {
          Title: 'Edit',
          Icon: EditOutlined,
          style: { fontSize: '16px', margin: '0px 10px 0px 0px' },
        },
        {
          Title: 'Delete',
          Icon: DeleteOutlined,
          style: {
            fontSize: '16px',
            ':hover': { fontSize: '18px' },
          },
        },
      ],
    },
  ];
  return (
    <div className="table-cotainer">
      <Modal
        isVisible={isVisible}
        handleOk={() => {
          deleteUniversity(selectedUni, () => setIsVisible(false));
        }}
        submitButtonLabel={'Yes'}
        cancelButtonLabel={'No'}
        handleCancel={() => setIsVisible(false)}
        title=""
        danger={true}
        loading={deleteLoading}
      >
        <div style={{ display: 'grid', textAlign: 'center' }}>
          <DeleteTwoTone
            style={{
              fontSize: 60,
              marginTop: 20,
              marginBottom: 10,
              color: 'red',
            }}
            twoToneColor="red"
          />
          <Text strong>Are you sure you want to delete?</Text>
        </div>
      </Modal>
      <Table
        setCurrentPage={setCurrentPage}
        columns={columns}
        total={total}
        data={data}
        perPage={10}
        loading={loading}
        IconClicked={IconClicked}
      />
    </div>
  );
};
UniversityTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setCurrentPage: PropTypes.func,
  getUniversity: PropTypes.func,
  selectUniversity: PropTypes.func,
  deleteUniversity: PropTypes.func,
  deleteLoading: PropTypes.bool,
};

export default UniversityTable;
