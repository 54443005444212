import {
  LOGIN,
  LOGINSUCCESS,
  LOGINFAILURE,
  EMAILCONFIRMATION,
  EMAILCONFIRMATIONDONE,
  EMAILCONFIRMATIONFAILED,
  RESETPASSWORD,
} from './constants';

const initialState = {
  isLoggedIn: false,
  token: '',
  error: '',
  emailConfirmationStarted: false,
  emailConfirmed: false,
  redirectToResetPassword: false,
  email: '',
  resetPasswordSuccess: false,
  resetPasswordStarted: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
      };
    }
    case LOGINSUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
      };
    }
    case LOGINFAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.error,
      };
    }
    case EMAILCONFIRMATION: {
      return {
        ...state,
        emailConfirmationStarted: true,
      };
    }
    case EMAILCONFIRMATIONDONE: {
      return {
        ...state,
        emailConfirmed: true,
        emailConfirmationStarted: false,
        redirectToResetPassword: true,
        email: action.payload,
      };
    }
    case EMAILCONFIRMATIONFAILED: {
      return {
        ...state,
        emailConfirmed: false,
        emailConfirmationStarted: false,
        error: 'email confirmation failed',
      };
    }
    case RESETPASSWORD: {
      return {
        ...state,
        resetPasswordStarted: true,
      };
    }
    default:
      return state;
  }
}
