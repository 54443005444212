import React, { useEffect, useState } from 'react';
import '../style.css';
import { Row, Col, Image, Checkbox, Button, Spin, message } from 'antd';

import PropTypes from 'prop-types';

const ThirdForm = ({
  onNextTap,
  getTemplates,
  templates,
  selectSlide,
  loading,
  templateId,
  templateLoading,
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    getTemplates();
  }, []);
  return (
    <div className="third-form-container">
      {loading ? (
        <Spin />
      ) : (
        <Row gutter={16}>
          {templates &&
            templates.map((t) => (
              <Col key={t.id} className="gutter-row" span={8}>
                {templateLoading && selected === t.id ? (
                  <Spin />
                ) : (
                  <Checkbox
                    onChange={() => {
                      setSelected(t.id);
                      selectSlide(t.id);
                    }}
                    checked={templateId === t.id}
                  />
                )}
                <Image width={'100%'} src={`${t.image_preview}`} />
              </Col>
            ))}
        </Row>
      )}
      <div className="fourth-form-button-container">
        <Button
          type="primary"
          style={{
            background: '#70C040',
            borderColor: '#70C040',
            borderRadius: 5,
          }}
          onClick={() =>
            templateId === null
              ? message.error('Please select a template!')
              : onNextTap()
          }
        >
          Done
        </Button>
      </div>
    </div>
  );
};

ThirdForm.propTypes = {
  getTemplates: PropTypes.func,
  onNextTap: PropTypes.func,
  templates: PropTypes.array,
  templateId: PropTypes.number,
  loading: PropTypes.bool,
  templateLoading: PropTypes.bool,
  selectSlide: PropTypes.func,
};

export default ThirdForm;
