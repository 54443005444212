import { takeLatest, put, call } from 'redux-saga/effects';
import {
  deleteUtil,
  getUtil,
  postUtil,
  setDefault,
} from '../../services/GradlineApi';

import {
  addFieldFailed,
  addFieldInitiate,
  addFieldSuccess,
  createAdminFailed,
  createAdminInit,
  createAdminSuccess,
  createUniversityFailed,
  createUniversityInitiate,
  createUniversitySuccess,
  deleteStudentFailed,
  deleteStudentIniate,
  deleteStudentSuccess,
  deleteUniversityFailed,
  deleteUniversityInitiate,
  deleteUniversitySuccess,
  formSubmissionFailed,
  formSubmissionInitiate,
  formSubmissionSuccess,
  getTemplatesFailed,
  getTemplatesInitiate,
  getTemplatesSuccess,
  getUniversityFailed,
  getUniversityInit,
  getUniversityListFailed,
  getUniversityListInit,
  getUniversityListSuccess,
  getUniversitySuccess,
  importStudentFailed,
  importStudentInitiate,
  importStudentSuccess,
  selectSlideFailed,
  selectSlideInit,
  selectSlideSuccess,
  updateNameFailed,
  updateNameInitiate,
  updateNameSuccess,
} from './actions';
import {
  ADD_NEW_FIELD,
  CREATE_ADMIN,
  CREATE_UNIVERSITY,
  DELETE_STUDENT,
  DELETE_UNIVERSITY,
  FORM_SUBMISSION,
  GET_TEMPLATES,
  GET_UNIVERSITY,
  GET_UNIVERSITY_LIST,
  IMPORT_LIST,
  SELECT_SLIDE,
  UPDATE_NAME,
} from './constants';

export function* getUniversity({ id, callBack }) {
  try {
    yield put(getUniversityInit());
    const url = `/university/${id}`;
    const response = yield call(getUtil, url);
    if (response.status === 200) {
      callBack();
      yield put(getUniversitySuccess(response.data.data));
    }
  } catch (err) {
    yield put(getUniversityFailed(err));
  }
}

export function* getUniversityList({ currentPage, limit }) {
  try {
    setDefault();
    yield put(getUniversityListInit());
    const url = `/universities?page=${currentPage}${
      limit ? `&limit=${limit}` : ''
    }`;
    const response = yield call(getUtil, url);
    if (response.status === 200) {
      yield put(getUniversityListSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getUniversityListFailed(err));
  }
}

export function* universityCreation({ data, callBack }) {
  try {
    yield put(createUniversityInitiate());

    const url = `/university/register`;
    const response = yield call(postUtil, url, data);
    if (response.status === 200 || response.status === 201) {
      yield put(createUniversitySuccess(response.data.data));
      callBack();
    }
  } catch (err) {
    yield put(createUniversityFailed(err));
  }
}

export function* addField({ data, callBack }) {
  try {
    yield put(addFieldInitiate());
    const url = `/university/form/new-field`;
    const response = yield call(postUtil, url, data);

    if (response.status === 200 || response.status === 201) {
      yield put(addFieldSuccess(response.data.data));
      callBack();
    }
  } catch (err) {
    yield put(addFieldFailed(err));
  }
}

export function* formSubmission({ data, callBack }) {
  try {
    yield put(formSubmissionInitiate());
    const url = `/university/form`;
    const response = yield call(postUtil, url, data);
    if (response.status === 200 || response.status === 201) {
      yield put(formSubmissionSuccess(response.data.data, data.form_title));
      callBack();
    }
  } catch (err) {
    yield put(formSubmissionFailed(err));
  }
}

export function* importStudetsList({ data, callBack }) {
  try {
    yield put(importStudentInitiate());
    const url = `/university/students`;
    const formData = new FormData();
    formData.append('university_id', data.id);
    formData.append('import_file', data.file);
    const response = yield call(postUtil, url, formData);
    if (response.status === 200 || response.status === 201) {
      yield put(importStudentSuccess(response.data.data));
      callBack();
    }
  } catch (err) {
    yield put(importStudentFailed(err));
  }
}

export function* updateName({ data, id, callBack }) {
  try {
    yield put(updateNameInitiate());
    const url = `/university/update/${id}`;
    const response = yield call(postUtil, url, data);
    if (response.status === 200 || response.status === 201) {
      callBack();
      yield put(updateNameSuccess(response.data.data));
    }
  } catch (err) {
    yield put(updateNameFailed(err));
  }
}

export function* deleteStudent({ id }) {
  try {
    yield put(deleteStudentIniate());
    const url = `/university/student/${id}`;
    const response = yield call(deleteUtil, url);
    if (response.status === 200 || response.status === 201) {
      yield put(deleteStudentSuccess(id));
    }
  } catch (err) {
    yield put(deleteStudentFailed(err));
  }
}

export function* deleteUniversity({ id, callBack }) {
  try {
    yield put(deleteUniversityInitiate());
    const url = `/university/${id}`;
    const response = yield call(deleteUtil, url);
    if (response.status === 200 || response.status === 201) {
      yield put(deleteUniversitySuccess(id));
      callBack();
    }
  } catch (err) {
    yield put(deleteUniversityFailed(err));
  }
}

export function* getTemplates() {
  try {
    yield put(getTemplatesInitiate());
    const url = `/slide-templates`;
    const response = yield call(getUtil, url);
    if (response.status === 200) {
      yield put(getTemplatesSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getTemplatesFailed(err));
  }
}

export function* selectSlide({ data }) {
  try {
    yield put(selectSlideInit());
    const url = `/university/template`;
    const response = yield call(postUtil, url, data);
    if (response.status === 200) {
      yield put(selectSlideSuccess(data.template_id));
    }
  } catch (err) {
    yield put(selectSlideFailed(err));
  }
}

export function* createAdmin({ data, callback }) {
  try {
    yield put(createAdminInit());
    const url = `/user`;
    const response = yield call(postUtil, url, data);
    callback();
    if (response.status === 200) {
      const user = {
        ...response.data.data,
        password: response.data.password,
      };
      yield put(createAdminSuccess(user));
    }
  } catch (err) {
    yield put(createAdminFailed(err));
  }
}

export const universitySaga = [
  takeLatest(GET_UNIVERSITY, getUniversity),
  takeLatest(GET_UNIVERSITY_LIST, getUniversityList),
  takeLatest(CREATE_UNIVERSITY, universityCreation),
  takeLatest(ADD_NEW_FIELD, addField),
  takeLatest(FORM_SUBMISSION, formSubmission),
  takeLatest(IMPORT_LIST, importStudetsList),
  takeLatest(UPDATE_NAME, updateName),
  takeLatest(DELETE_STUDENT, deleteStudent),
  takeLatest(DELETE_UNIVERSITY, deleteUniversity),
  takeLatest(GET_TEMPLATES, getTemplates),
  takeLatest(SELECT_SLIDE, selectSlide),
  takeLatest(CREATE_ADMIN, createAdmin),
];
