import React, { useEffect, useState } from 'react';
import { Table } from '../../../../components';
import {
  DownloadOutlined,
  LoadingOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { STUDENTS_TYPES, UPDATE_SELECTED_STUDENTS } from '../../constants';

const StudentTable = ({
  data,
  studentSelect,
  loading,
  updatedSelectedStudents,
  limit,
  setCurrentPage,
  total,
  activeTab,
  downloadPresentation,
  downloadLoading,
}) => {
  const [columnsRecorded, setColumnsRecorded] = useState([
    {
      title: 'Student ID',
      targetValue: 'id',
      key: 'id',
    },
    {
      title: 'Student Name',
      targetValue: 'first_name',
      key: 'name',
    },
    {
      title: 'Email',
      targetValue: 'email',
      key: 'email',
    },
    {
      title: 'Date Created',
      targetValue: 'created_at',
      key: 'date',
    },
  ]);

  const columnsUnRecorded = [
    {
      title: 'Student ID',
      targetValue: 'id',
      key: 'id',
    },
    {
      title: 'Student Name',
      targetValue: 'first_name',
      key: 'name',
    },
    {
      title: 'Email',
      targetValue: 'email',
      key: 'email',
    },
    {
      title: 'Date Created',
      targetValue: 'created_at',
      key: 'date',
    },
    {
      title: 'Actions',
      render: [
        {
          Title: 'View',
          Icon: EyeOutlined,
          style: {
            fontSize: '16px',
            margin: '0px 10px 0px 0px',
            ':hover': { fontSize: '18px' },
          },
        },
      ],
    },
  ];
  const columnsRequested = [
    {
      title: 'Student ID',
      targetValue: 'id',
      key: 'id',
    },
    {
      title: 'Student Name',
      targetValue: 'first_name',
      key: 'name',
    },
    {
      title: 'Email',
      targetValue: 'email',
      key: 'email',
    },
    {
      title: 'Date Created',
      targetValue: 'created_at',
      key: 'date',
    },
    {
      title: 'Form Link',
      targetValue: 'form_link',
      key: 'link',
    },
    {
      title: 'Actions',
      render: [
        {
          Title: 'View',
          Icon: EyeOutlined,
          style: {
            fontSize: '16px',
            margin: '0px 10px 0px 0px',
            ':hover': { fontSize: '18px' },
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (downloadLoading) {
      setColumnsRecorded([
        ...columnsRecorded.slice(0, columnsRecorded.length - 1),
        {
          title: 'Actions',
          render: [
            {
              Title: 'View',
              Icon: EyeOutlined,
              style: {
                fontSize: '16px',
                margin: '0px 10px 0px 0px',
                ':hover': { fontSize: '18px' },
              },
            },
            {
              Title: 'Action',
              Icon: LoadingOutlined,
            },
          ],
        },
      ]);
    } else {
      setColumnsRecorded([
        ...columnsRecorded.slice(0, columnsRecorded.length - 1),
        {
          title: 'Actions',
          render: [
            {
              Title: 'View',
              Icon: EyeOutlined,
              style: {
                fontSize: '16px',
                margin: '0px 10px 0px 0px',
                ':hover': { fontSize: '18px' },
              },
            },
            {
              Title: 'Action',
              Icon: DownloadOutlined,
            },
          ],
        },
      ]);
    }
  }, [downloadLoading]);

  const IconClicked = (row, type) => {
    switch (type) {
      case 'Action':
        {
          downloadPresentation(row.id);
        }
        break;
      case 'View':
        {
          studentSelect(row);
        }
        break;
      default:
        console.log('error');
    }
  };

  const columns =
    activeTab === STUDENTS_TYPES.RECORDED
      ? columnsRecorded
      : activeTab === STUDENTS_TYPES.UNRECORDED
      ? columnsUnRecorded
      : columnsRequested;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      var keys = selectedRows.map((a) => a.id);
      updatedSelectedStudents(keys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
  };

  let tableProps = {
    setCurrentPage: setCurrentPage,
    total: total,
    loading: loading,
    columns: columns,
    perPage: limit,
    data: data,
  };

  if (activeTab === STUDENTS_TYPES.UNRECORDED) {
    tableProps.rowSelection = {
      ...rowSelection,
    };
  }

  return <Table {...tableProps} IconClicked={IconClicked} />;
};

StudentTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  columClicked: PropTypes.func,
  studentSelect: PropTypes.func,
  updatedSelectedStudents: PropTypes.func,
  studentState: PropTypes.object,
  total: PropTypes.number,
  setCurrentPage: PropTypes.func,
  limit: PropTypes.number,
  activeTab: PropTypes.string,
  downloadPresentation: PropTypes.func,
  downloadLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    studentState: state.studentState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updatedSelectedStudents: (payload) => {
    dispatch({
      type: UPDATE_SELECTED_STUDENTS,
      payload,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable);
