import React from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImageUploader = ({ disabled, fileGet }) => {
  const props = {
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error(`${file.name} is not a .png OR .jpg`);
      }
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        message.error('Image must smaller than 1MB!');
      }
      fileGet(file);
      return isJpgOrPng && isLt2M ? true : Upload.LIST_IGNORE;
    },
    onChange: () => {},
  };

  return (
    <Upload {...props} maxCount={1}>
      <Button
        disabled={disabled}
        type="primary"
        size="middle"
        style={{
          borderRadius: '6px',
          marginTop: '5%',
          width: '120px',
          height: '36px',
          backgroundColor: '#73d13d',
          borderColor: '#73d13d',
          outlineColor: '#73d13d',
        }}
        icon={<UploadOutlined />}
      >
        Upload
      </Button>
    </Upload>
  );
};

ImageUploader.propTypes = {
  disabled: PropTypes.bool,
  fileGet: PropTypes.func,
};

export default ImageUploader;
