import {
  CREATE_UNIVERSITY_INIT,
  CREATE_UNIVERSITY_SUCCESS,
  CREATE_UNIVERSITY_FAILED,
  GET_UNIVERSITY_INIT,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_FAILED,
  ADD_NEW_FIELD_INIT,
  ADD_NEW_FIELD_SUCCESS,
  ADD_NEW_FIELD_FAILED,
  UPDATE_STATUS,
  IMPORT_LIST_INIT,
  IMPORT_LIST_FAILED,
  IMPORT_LIST_SUCCESS,
  GET_UNIVERSITY_LIST_INIT,
  GET_UNIVERSITY_LIST_SUCCESS,
  GET_UNIVERSITY_LIST_FAILED,
  UPDATE_NAME_INIT,
  UPDATE_NAME_SUCCESS,
  UPDATE_NAME_FAILED,
  FORM_SUBMISSION_INIT,
  FORM_SUBMISSION_SUCCESS,
  FORM_SUBMISSION_FAILED,
  DELETE_STUDENT_FAILED,
  DELETE_STUDENT_INIT,
  DELETE_STUDENT_SUCCESS,
  RESET_STATE,
  GET_TEMPLATES_INIT,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  SELECT_SLIDE_INIT,
  SELECT_SLIDE_SUCCESS,
  SELECT_SLIDE_FAILED,
  DELETE_UNIVERSITY_INIT,
  DELETE_UNIVERSITY_FAILED,
  DELETE_UNIVERSITY_SUCCESS,
  CREATE_ADMIN_INIT,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
} from './constants';

const initialState = {
  loading: false,
  dataLoading: false,
  addFieldLoading: false,
  formRules: {},
  universities: [],
  students: [],
  universityAdmins: [],
  total: 0,
  templates: [],
  templateId: null,
  templateLoading: false,
  deleteLoading: false,
  adminCreationLoading: false,
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        formRules: {},
        universityId: null,
        students: [],
        universityAdmins: [],
      };
    case GET_UNIVERSITY_INIT:
      return {
        ...state,
        loading: true,
        dataLoading: true,
      };
    case GET_UNIVERSITY_SUCCESS:
      var rulesGet = {
        university_id: action.data.university.id,
        logo: action.data.university.logo,
        form: action.data.form,
        name: action.data.university.name,
        properties: action.data.properties,
      };
      return {
        ...state,
        loading: false,
        dataLoading: false,
        students: [...action.data.university.students],
        universityAdmins: [...action.data.university.university_admins],
        formRules: { ...rulesGet },
        templateId: action.data.university.html_template_id,
      };
    case GET_UNIVERSITY_FAILED:
      return {
        ...state,
        loading: false,
        dataLoading: false,
      };

    case GET_UNIVERSITY_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_UNIVERSITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        universities: [...action.data.data],
        total: action.data.total,
      };
    case GET_UNIVERSITY_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CREATE_UNIVERSITY_INIT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        formRules: { ...action.data, form: null },
      };
    case CREATE_UNIVERSITY_FAILED:
      return {
        ...state,
        loading: false,
      };

    case FORM_SUBMISSION_INIT:
      return {
        ...state,
        loading: true,
      };
    case FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        formRules: {
          ...action.data,
          name: state.formRules.name,
          form: {
            id: action.data.form_id,
            title: action.name,
          },
        },
      };
    case FORM_SUBMISSION_FAILED:
      return {
        ...state,
        loading: false,
      };

    case ADD_NEW_FIELD_INIT:
      return {
        ...state,
        addFieldLoading: true,
      };
    case ADD_NEW_FIELD_SUCCESS:
      var index = state.formRules.properties.findIndex(
        (a) => a.type === 'radiobutton'
      );
      var upadtedFormRules = Object.assign({}, state.formRules, {
        properties: [
          ...state.formRules.properties.slice(0, index),
          action.data,
          ...state.formRules.properties.slice(
            index,
            state.formRules.properties.length
          ),
        ],
      });

      return {
        ...state,
        addFieldLoading: false,
        formRules: { ...upadtedFormRules },
      };
    case ADD_NEW_FIELD_FAILED:
      return {
        ...state,
        addFieldLoading: false,
      };

    case UPDATE_STATUS:
      var indexFind = state.formRules.properties.findIndex(
        (p) => p.id === action.id
      );
      var updatedAttribute = Object.assign(
        {},
        state.formRules.properties[indexFind].form_attribute_university[0],
        {
          status: !state.formRules.properties[indexFind]
            .form_attribute_university[0].status,
        }
      );
      var updatedProperty = Object.assign(
        {},
        state.formRules.properties[indexFind],
        {
          form_attribute_university: [updatedAttribute],
        }
      );

      var upadtedRules = Object.assign({}, state.formRules, {
        properties: [
          ...state.formRules.properties.slice(0, indexFind),
          updatedProperty,
          ...state.formRules.properties.slice(
            indexFind + 1,
            state.formRules.properties.length
          ),
        ],
      });

      return {
        ...state,
        formRules: { ...upadtedRules },
      };

    case IMPORT_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        students: [...action.data],
      };
    case IMPORT_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_NAME_INIT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NAME_FAILED:
      return {
        ...state,
        loading: false,
      };

    case DELETE_UNIVERSITY_INIT:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_UNIVERSITY_SUCCESS:
      var updatedUniversitesList = state.universities.filter(
        (u) => u.id !== action.id
      );
      return {
        ...state,
        universities: [...updatedUniversitesList],
        deleteLoading: false,
      };
    case DELETE_UNIVERSITY_FAILED:
      return {
        ...state,
        deleteLoading: false,
      };

    case DELETE_STUDENT_INIT:
      return {
        ...state,
      };
    case DELETE_STUDENT_SUCCESS:
      var updatedStudnets = state.students.filter((s) => s.id !== action.id);
      return {
        ...state,
        students: [...updatedStudnets],
      };
    case DELETE_STUDENT_FAILED:
      return {
        ...state,
      };

    case GET_TEMPLATES_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        templates: action.data,
      };
    }
    case GET_TEMPLATES_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case SELECT_SLIDE_INIT: {
      return {
        ...state,
        templateLoading: true,
      };
    }
    case SELECT_SLIDE_SUCCESS: {
      return {
        ...state,
        templateId: action.id,
        templateLoading: false,
      };
    }
    case SELECT_SLIDE_FAILED: {
      return {
        ...state,
        templateLoading: false,
      };
    }

    case CREATE_ADMIN_INIT: {
      return {
        ...state,
        adminCreationLoading: true,
      };
    }
    case CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        universityAdmins: [...state.universityAdmins, { ...action.data }],
        adminCreationLoading: false,
      };
    }
    case CREATE_ADMIN_FAILED: {
      return {
        ...state,
        adminCreationLoading: false,
      };
    }
    default:
      return state;
  }
}
