export const UPDATE_NAME = 'UNIVERSITY@UPDATE_NAME';
export const UPDATE_NAME_INIT = 'UNIVERSITY@UPDATE_NAME_INIT';
export const UPDATE_NAME_SUCCESS = 'UNIVERSITY@UPDATE_NAME_SUCCESS';
export const UPDATE_NAME_FAILED = 'UNIVERSITY@UPDATE_NAME_FAILED';

export const GET_UNIVERSITY = 'UNIVERSITY@GET_UNIVERSITY';
export const GET_UNIVERSITY_INIT = 'UNIVERSITY@GET_UNIVERSITY_INIT';
export const GET_UNIVERSITY_SUCCESS = 'UNIVERSITY@GET_UNIVERSITY_SUCCESS';
export const GET_UNIVERSITY_FAILED = 'UNIVERSITY@GET_UNIVERSITY_FAILED';

export const GET_TEMPLATES = 'UNIVERSITY@GET_TEMPLATES';
export const GET_TEMPLATES_INIT = 'UNIVERSITY@GET_TEMPLATES_INIT';
export const GET_TEMPLATES_SUCCESS = 'UNIVERSITY@GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILED = 'UNIVERSITY@GET_TEMPLATES_FAILED';

export const CREATE_UNIVERSITY = 'UNIVERSITY@CREATE_UNIVERSITY';
export const CREATE_UNIVERSITY_INIT = 'UNIVERSITY@CREATE_UNIVERSITY_INIT';
export const CREATE_UNIVERSITY_SUCCESS = 'UNIVERSITY@CREATE_UNIVERSITY_SUCCESS';
export const CREATE_UNIVERSITY_FAILED = 'UNIVERSITY@CREATE_UNIVERSITY_FAILED';

export const ADD_NEW_FIELD = 'UNIVERSITY@ADD_NEW_FIELD';
export const ADD_NEW_FIELD_INIT = 'UNIVERSITY@ADD_NEW_FIELD_INIT';
export const ADD_NEW_FIELD_SUCCESS = 'UNIVERSITY@ADD_NEW_FIELD_SUCCESS';
export const ADD_NEW_FIELD_FAILED = 'UNIVERSITY@ADD_NEW_FIELD_FAILED';

export const FORM_SUBMISSION = 'UNIVERSITY@FORM_SUBMISSION';
export const FORM_SUBMISSION_INIT = 'UNIVERSITY@FORM_SUBMISSION_INIT';
export const FORM_SUBMISSION_SUCCESS = 'UNIVERSITY@FORM_SUBMISSION_SUCCESS';
export const FORM_SUBMISSION_FAILED = 'UNIVERSITY@FORM_SUBMISSION_FAILED';

export const UPDATE_STATUS = 'UNIVERSITY@UPDATE_STATUS';

export const IMPORT_LIST = 'UNIVERSITY@IMPORT_LIST';
export const IMPORT_LIST_INIT = 'UNIVERSITY@IMPORT_LIST_INIT';
export const IMPORT_LIST_SUCCESS = 'UNIVERSITY@IMPORT_LIST_SUCCESS';
export const IMPORT_LIST_FAILED = 'UNIVERSITY@IMPORT_LIST_FAILED';

export const GET_UNIVERSITY_LIST = 'UNIVERSITY@GET_UNIVERSITY_LIST';
export const GET_UNIVERSITY_LIST_INIT = 'UNIVERSITY@GET_UNIVERSITY_LIST_INIT';
export const GET_UNIVERSITY_LIST_SUCCESS =
  'UNIVERSITY@GET_UNIVERSITY_LIST_SUCCESS';
export const GET_UNIVERSITY_LIST_FAILED =
  'UNIVERSITY@GET_UNIVERSITY_LIST_FAILED';

export const DELETE_STUDENT = 'UNIVERSITY@DELETE_STUDENT';
export const DELETE_STUDENT_INIT = 'UNIVERSITY@DELETE_STUDENT_INIT';
export const DELETE_STUDENT_SUCCESS = 'UNIVERSITY@DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILED = 'UNIVERSITY@DELETE_STUDENT_FAILED';

export const DELETE_UNIVERSITY = 'UNIVERSITY@DELETE_UNIVERSITY';
export const DELETE_UNIVERSITY_INIT = 'UNIVERSITY@DELETE_UNIVERSITY_INIT';
export const DELETE_UNIVERSITY_SUCCESS = 'UNIVERSITY@DELETE_UNIVERSITY_SUCCESS';
export const DELETE_UNIVERSITY_FAILED = 'UNIVERSITY@DELETE_UNIVERSITY_FAILED';

export const SELECT_SLIDE = 'UNIVERSITY@SELECT_SLIDE';
export const SELECT_SLIDE_INIT = 'UNIVERSITY@SELECT_SLIDE_INIT';
export const SELECT_SLIDE_SUCCESS = 'UNIVERSITY@SELECT_SLIDE_SUCCESS';
export const SELECT_SLIDE_FAILED = 'UNIVERSITY@SELECT_SLIDE_FAILED';

export const RESET_STATE = 'UNIVERSITY@RESET_STATE';

export const CREATE_ADMIN = 'UNIVERSITY@CREATE_ADMIN';
export const CREATE_ADMIN_INIT = 'UNIVERSITY@CREATE_ADMIN_INIT';
export const CREATE_ADMIN_SUCCESS = 'UNIVERSITY@CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILED = 'UNIVERSITY@CREATE_ADMIN_FAILED';
