import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from '../Layout';
import './styles.css';
import { Row, Col, Image, Checkbox, Spin } from 'antd';
import {
  getTemplates,
  selectSlide,
  getUniversity,
} from '../University/actions';
import { useHistory } from 'react-router-dom';

const Slides = ({
  templates,
  templateId,
  templateLoading,
  loading,
  selectedUniversityId,
  getUni,
  getTemplatesAction,
  selectSlideAction,
}) => {
  const [selected, setSelected] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getTemplatesAction();
    if (selectedUniversityId !== null) {
      getUni(selectedUniversityId, () => {});
    } else {
      history.push('/universities');
    }
  }, []);

  return (
    <Layout>
      <div className="slides-container">
        {loading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            {templates &&
              templates.map((t) => (
                <Col key={t.id} className="gutter-row" span={8}>
                  {templateLoading && selected === t.id ? (
                    <Spin />
                  ) : (
                    <Checkbox
                      onChange={() => {
                        setSelected(t.id);
                        selectSlideAction({
                          university_id: selectedUniversityId,
                          template_id: t.id,
                        });
                      }}
                      checked={templateId === t.id}
                    />
                  )}
                  <Image width={'100%'} src={`${t.image_preview}`} />
                </Col>
              ))}
          </Row>
        )}
      </div>
    </Layout>
  );
};

Slides.propTypes = {
  templates: PropTypes.array,
  templateId: PropTypes.number,
  templateLoading: PropTypes.bool,
  loading: PropTypes.bool,
  getUni: PropTypes.func,
  getTemplatesAction: PropTypes.func,
  selectedUniversityId: PropTypes.number,
  selectSlideAction: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { selectedUniversityId } = state.layoutState;
  const {
    templates,
    templateId,
    templateLoading,
    loading,
  } = state.universityState;
  return {
    templates,
    templateId,
    templateLoading,
    loading,
    selectedUniversityId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getTemplatesAction: () => {
    dispatch(getTemplates());
  },
  selectSlideAction: (data) => {
    dispatch(selectSlide(data));
  },
  getUni: (id, callBack) => {
    dispatch(getUniversity(id, callBack));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Slides);
