import {
  GET_STUDNETS,
  GET_STUDNETS_INIT,
  GET_STUDNETS_FAILED,
  GET_STUDNETS_SUCCESS,
  DOWNLOAD_PRESENTATION,
  DOWNLOAD_PRESENTATION_INIT,
  DOWNLOAD_PRESENTATION_FAILED,
  DOWNLOAD_PRESENTATION_SUCCESS,
  GET_STUDENT_INFO,
  GET_STUDENT_INFO_SUCCESS,
  GET_STUDENT_INFO_INIT,
  GET_STUDENT_INFO_FAILED,
} from './constants';

//get student details
export function getStudentInfo(id) {
  return {
    type: GET_STUDENT_INFO,
    id,
  };
}

export function getStudentInfoInit() {
  return {
    type: GET_STUDENT_INFO_INIT,
  };
}

export function getStudentInfoFailed(error) {
  return {
    type: GET_STUDENT_INFO_FAILED,
    error,
  };
}

export function getStudentInfoSuccess(data) {
  return {
    type: GET_STUDENT_INFO_SUCCESS,
    data,
  };
}

//download presentation
export function downloadPresentation(id) {
  return {
    type: DOWNLOAD_PRESENTATION,
    id,
  };
}

export function downloadPresentationInit() {
  return {
    type: DOWNLOAD_PRESENTATION_INIT,
  };
}

export function downloadPresentationFailed(error) {
  return {
    type: DOWNLOAD_PRESENTATION_FAILED,
    error,
  };
}

export function downloadPresentationSuccess() {
  return {
    type: DOWNLOAD_PRESENTATION_SUCCESS,
  };
}

// Get Students
export function getStudents(data) {
  return {
    type: GET_STUDNETS,
    data,
  };
}

export function getStudentsInit() {
  return {
    type: GET_STUDNETS_INIT,
  };
}

export function getStudentsFailed(error) {
  return {
    type: GET_STUDNETS_FAILED,
    error,
  };
}

export function getStudentsSuccess(data) {
  return {
    type: GET_STUDNETS_SUCCESS,
    data,
  };
}

export function getStudentsInfo(payload) {
  return {
    type: GET_STUDENT_INFO,
    payload,
  };
}
