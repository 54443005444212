import React, { useState, useEffect } from 'react';
import { Tabs, Button, Card, Modal } from 'antd';
import PropTypes from 'prop-types';
import { DownloadOutlined, MailFilled } from '@ant-design/icons';
import StudentTable from './Components/StudentTable';
import { CHANGE_STUDENT_DETAIL_NAV } from '../Layout/constants';
import StudentDetail from './Components/StudentsDetails/StudentDetail';
import StudentsReview from './Components/StudentsReview/StudentsReview';
import { connect } from 'react-redux';
import {
  SEND_EMAIL_TO_SELECTED_STUDENTS,
  STUDENTS_TYPES,
  UPDATE_SELECTED_STUDENTS,
} from './constants';
import Layout from '../Layout';
import { getStudents, downloadPresentation, getStudentInfo } from './actions';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;
const LIMIT = 20;

const StudentDashboard = ({
  navStudentDetail,
  selectedStudents,
  insideStudentDetail,
  sendEmail,
  getStudentsList,
  students,
  total,
  selectedUniversityId,
  loading,
  downloadPresentationAction,
  getStudentInfoAction,
  student,
  studentloading,
  downloadLoading,
}) => {
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(STUDENTS_TYPES.RECORDED);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (selectedUniversityId !== null) {
      getData();
    } else {
      history.push('/universities');
    }
  }, [selectedUniversityId, activeTab, page]);

  const tabs = [
    {
      id: STUDENTS_TYPES.RECORDED,
      Title: 'Recorded Names',
    },
    {
      id: STUDENTS_TYPES.UNRECORDED,
      Title: 'Unrecorded Names',
    },
    {
      id: STUDENTS_TYPES.REQUESTD,
      Title: 'Names Requested',
    },
  ];

  const getData = (refreshPage) => {
    getStudentsList({
      id: selectedUniversityId,
      page: refreshPage ? 1 : page,
      limit: LIMIT,
      type: activeTab,
    });
  };

  const getStudentInfo = (row) => {
    navStudentDetail();
    setSelectedStudent(row);
    getStudentInfoAction(row.id);
  };

  const handleMail = () => {
    sendEmail(selectedStudents, () => {
      setActiveTab(STUDENTS_TYPES.REQUESTD);
    });
  };

  const openReviewModal = () => {
    setIsReviewModalVisible(true);
  };

  const closeReviewModal = () => {
    setIsReviewModalVisible(false);
  };

  return (
    <Layout>
      {!insideStudentDetail ? (
        <Card className="tab-conatiner">
          <Tabs
            defaultActiveKey="1"
            size="large"
            activeKey={activeTab}
            onChange={(key) => {
              setActiveTab(key);
            }}
            style={{ marginBottom: 32 }}
            tabBarExtraContent={
              activeTab === STUDENTS_TYPES.RECORDED && (
                <Button onClick={openReviewModal}>Review</Button>
              )
            }
          >
            {tabs.map((tab) => {
              return (
                <TabPane
                  tab={tab.Title}
                  key={tab.id}
                  className="tab-inner-container"
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {activeTab === STUDENTS_TYPES.UNRECORDED && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          marginBottom: '20px',
                        }}
                      >
                        <Button
                          type="primary"
                          icon={<MailFilled />}
                          style={{
                            marginLeft: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={handleMail}
                        />
                        <Button
                          type="primary"
                          icon={<DownloadOutlined />}
                          style={{
                            marginLeft: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </div>
                    )}
                    <StudentTable
                      downloadLoading={downloadLoading}
                      total={total}
                      activeTab={activeTab}
                      setCurrentPage={setPage}
                      limit={LIMIT}
                      data={students}
                      downloadPresentation={(id) =>
                        downloadPresentationAction(id)
                      }
                      studentSelect={getStudentInfo}
                      loading={loading}
                    />
                  </div>
                </TabPane>
              );
            })}
          </Tabs>
        </Card>
      ) : (
        <StudentDetail
          student={student}
          loading={studentloading}
          studentId={selectedStudent.id}
        />
      )}
      <Modal
        title="Review Students"
        visible={isReviewModalVisible}
        onOk={closeReviewModal}
        onCancel={closeReviewModal}
        width={1000}
      >
        <StudentsReview
          students={students}
          loading={loading}
          total={total}
          page={page}
          setCurrentPage={setPage}
          limit={LIMIT}
        />
      </Modal>
    </Layout>
  );
};

StudentDashboard.propTypes = {
  students: PropTypes.array,
  insideStudentDetail: PropTypes.bool,
  navStudentDetail: PropTypes.func,
  selectedStudents: PropTypes.any,
  student: PropTypes.object,
  studentloading: PropTypes.bool,
  sendEmail: PropTypes.func,
  loading: PropTypes.bool,
  getStudentsList: PropTypes.func,
  total: PropTypes.number,
  selectedUniversityId: PropTypes.number,
  downloadPresentationAction: PropTypes.func,
  getStudentInfoAction: PropTypes.func,
  downloadLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { insideStudentDetail, selectedUniversityId } = state.layoutState;
  const {
    students,
    total,
    loading,
    student,
    selectedStudents,
    studentloading,
    downloadLoading,
  } = state.studentState;
  return {
    insideStudentDetail,
    students,
    selectedUniversityId,
    total,
    loading,
    selectedStudents,
    student,
    studentloading,
    downloadLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  navStudentDetail: () => {
    dispatch({
      type: CHANGE_STUDENT_DETAIL_NAV,
    });
  },
  updatedSelectedStudents: (payload) => {
    dispatch({
      type: UPDATE_SELECTED_STUDENTS,
      payload,
    });
  },
  getStudentInfoAction: (id) => {
    dispatch(getStudentInfo(id));
  },
  sendEmail: (payload, callback) => {
    dispatch({
      type: SEND_EMAIL_TO_SELECTED_STUDENTS,
      payload,
      callback,
    });
  },
  getStudentsList: (data) => {
    dispatch(getStudents(data));
  },
  downloadPresentationAction: (id) => {
    dispatch(downloadPresentation(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
