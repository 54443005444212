import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from './components/Header';
import { getForm, updateValue, submitForm } from './actions';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StudentForm } from '../../components';
import { Spin, message, Typography } from 'antd';
import { CheckCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import './styles.css';
import LogRocket from 'logrocket';

const { Link, Text } = Typography;
const StudentFormContainer = ({
  getFormAction,
  formRules,
  loading,
  updatePropertyValue,
  submitFormAction,
  formLoading,
  isFormSubmitted,
}) => {
  const { token } = useParams();
  const [firstCall, setFirstCall] = useState(true);

  useEffect(() => {
    getFormAction(token);
    LogRocket.getSessionURL((sessionURL) => {
      console.log(sessionURL);
    });
  }, []);

  const fetchId = (key) => {
    const rules = formRules.properties.filter((a) => a.name === key);
    return rules[0].id;
  };
  useEffect(() => {
    if (firstCall && Object.keys(formRules).length) {
      updatePropertyValue(fetchId('email'), formRules.student.email);
      updatePropertyValue(fetchId('first_name'), formRules.student.first_name);
      updatePropertyValue(fetchId('last_name'), formRules.student.last_name);
      updatePropertyValue(
        fetchId('middle_name'),
        formRules.student.middle_name
      );
      updatePropertyValue(fetchId('honours'), formRules.student.honours);
      try {
        console.log('logrocket.identify =', formRules.student);
        LogRocket.identify(formRules.student.email);
      } catch (err) {
        console.log('LogRocket.identify failed with', err);
      }
      setFirstCall(false);
    }
  }, [formRules]);

  const submitForm = () => {
    const invalidTextValues = formRules.properties.filter(
      (a) => a.is_required && (!a.value || a.value === null || a.value === '')
    );
    if (invalidTextValues.length) {
      if (invalidTextValues[0].type === 'radiobutton') {
        if (invalidTextValues[0].slug === 'voice-record-type') {
          message.error(`Please record your name or updload voice recording.`);
        } else {
          message.error(`Please updload profile image.`);
        }
      } else {
        message.error(`${invalidTextValues[0].label} is required.`);
      }
      return;
    }
    const formData = new FormData();
    formData.append(`university_id`, formRules.university_id);
    formData.append(`form_token`, token);
    formRules.properties.map((p, i) => {
      if (p.form_attribute_university[0].status === 1) {
        if (p.type === 'radiobutton') {
          formData.append(`student_data[${i}][form_attribute_id]`, p.child.id);
          formData.append(`student_data[${i}][value]`, p.value);
        } else {
          if (p.value) {
            formData.append(`student_data[${i}][form_attribute_id]`, p.id);
            formData.append(`student_data[${i}][value]`, p.value);
          }
        }
      }
    });
    submitFormAction(formData);
  };

  const offset = new Date().getTimezoneOffset(); // getting offset to make time in gmt+0 zone (UTC) (for gmt+5 offset comes as -300 minutes)
  const easternTimeOffset = offset - 240; //for dayLight saving, Eastern time become 4 hours behind UTC thats why its offset is -4x60 = -240 minutes. So when Day light is not active the offset will be -300

  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + easternTimeOffset);
  var x = 0;
  return (
    <div style={{}}>
      <Header />
      {loading ? (
        <div className="form-board-container">
          <Spin />
        </div>
      ) : x === 1 ? (
        <div className="success-message-container">
          <InfoCircleTwoTone style={{ fontSize: 80, marginTop: 50 }} />
          <Text style={{ fontSize: 24, marginTop: 20 }}>
            Registration is now closed.
          </Text>
          <Text>
            Please email&nbsp;
            <Link href="mailto:convocation@georgebrown.ca">
              convocation@georgebrown.ca
            </Link>
            &nbsp;with any questions.
          </Text>
        </div>
      ) : Object.keys(formRules).length && !isFormSubmitted ? (
        <div className="student-form-container">
          <StudentForm
            loading={formLoading}
            formRules={formRules}
            updateValue={(id, value) => updatePropertyValue(id, value)}
            audioFileGet={(id, file) => {
              updatePropertyValue(id, file);
            }}
            imageGet={(id, image) => {
              updatePropertyValue(id, image);
            }}
            submitLabel="Submit"
            formSubmission={() => submitForm()}
          />
        </div>
      ) : (
        <div className="success-message-container">
          <CheckCircleTwoTone
            style={{ fontSize: 80, marginTop: 50 }}
            twoToneColor="#52c41a"
          />
          <Text style={{ fontSize: 24, marginTop: 20 }}>
            Your Form has been Submitted!
          </Text>
        </div>
      )}
    </div>
  );
};

StudentFormContainer.propTypes = {
  getFormAction: PropTypes.func,
  formRules: PropTypes.object,
  loading: PropTypes.bool,
  updatePropertyValue: PropTypes.func,
  submitFormAction: PropTypes.func,
  formLoading: PropTypes.bool,
  isFormSubmitted: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    formRules,
    loading,
    formLoading,
    isFormSubmitted,
  } = state.studentFormState;
  return {
    formRules,
    loading,
    formLoading,
    isFormSubmitted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getFormAction: (token) => {
    dispatch(getForm(token));
  },
  updatePropertyValue: (id, value) => {
    dispatch(updateValue(id, value));
  },
  submitFormAction: (data) => {
    LogRocket.info('form submitted', data);
    dispatch(submitForm(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFormContainer);
