import React, { useState } from 'react';
import '../style.css';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { Input } from '../../../components';
import { Table, Modal } from '../../../components';
// import { DeleteOutlined } from '@ant-design/icons';
const AdminTab = ({ loading, universityAdmins, createAdmin }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const IconClicked = (row, type, dataSource = []) => {
    let index = dataSource.findIndex((c) => c.key === row.key);
    console.log(index);
    switch (type) {
      case 'Delete':
        {
          console.log('daa');
        }
        break;
      default:
        console.log('error');
    }
  };
  const columns = [
    {
      title: 'First Name',
      targetValue: 'first_name',
    },
    {
      title: 'Last Name',
      targetValue: 'last_name',
    },
    {
      title: 'Email',
      targetValue: 'email',
    },
    // {
    //   title: 'Passowrd',
    //   targetValue: 'password',
    // },
    // {
    //   title: 'Actions',
    //   targetValue: '',
    //   render: [
    //     {
    //       Title: 'Delete',
    //       Icon: DeleteOutlined,
    //       style: {
    //         fontSize: '16px',
    //         ':hover': { fontSize: '18px' },
    //       },
    //     },
    //   ],
    // },
  ];

  const resetState = () => {
    setIsVisible(false);
    setEmail('');
    setLastname('');
    setFirstname('');
  };
  return (
    <div className="admin-tab-container">
      <Modal
        isVisible={isVisible}
        handleOk={() => {
          if (firstname === '' || lastname === '' || email === '') {
            message.error('All fields are required!');
            return;
          }
          if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              email
            )
          ) {
            createAdmin(
              {
                first_name: firstname,
                last_name: lastname,
                email: email,
                password: Math.random().toString(36).substring(7),
              },
              () => resetState()
            );
          } else {
            message.error('Email is invalid!');
          }
        }}
        submitButtonLabel="Add"
        cancelButtonLabel="Cancel"
        handleCancel={() => setIsVisible(false)}
        title={'Add Admin'}
        loading={loading}
      >
        <div className="admin-tab-inner-container">
          <Input
            label="First Name"
            placeholder="First Name"
            value={firstname}
            onChange={setFirstname}
          />
          <Input
            label="Last Name"
            placeholder="Last Name"
            value={lastname}
            onChange={setLastname}
          />
          <Input
            label="Email"
            placeholder="Email"
            value={email}
            onChange={setEmail}
          />
        </div>
      </Modal>
      <div className="admin-button-container">
        <Button
          type="primary"
          style={{
            background: '#70C040',
            borderColor: '#70C040',
            borderRadius: 5,
          }}
          onClick={() => setIsVisible(true)}
        >
          Add Admin
        </Button>
      </div>
      <div className="admin-tab-inner-container">
        <Table
          setCurrentPage={() => {}}
          columns={columns}
          total={universityAdmins.length}
          data={universityAdmins}
          perPage={10}
          loading={false}
          IconClicked={IconClicked}
        />
      </div>
    </div>
  );
};

AdminTab.propTypes = {
  loading: PropTypes.bool,
  universityAdmins: PropTypes.array,
  createAdmin: PropTypes.func,
};

export default AdminTab;
