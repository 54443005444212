import React, { useState, useEffect } from 'react';
import '../style.css';
import PropTypes from 'prop-types';
import { Button, message, Card, Typography, Image } from 'antd';
import { Input } from '../../../components';
import Upload from '../../../components/StudentForm/ImageUploader';

const { Text } = Typography;

const FirstForm = ({
  createUniversity,
  loading,
  name,
  isEdit,
  uploadedLogo,
}) => {
  const [university, setUniversity] = useState('');
  const [logo, setLogo] = useState({});
  const [uniLogo, setUniLogo] = useState(null);
  useEffect(() => {
    if (isEdit) {
      setUniLogo(uniLogo);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setUniversity(name);
    }
  }, [name]);

  const createUni = () => {
    const formData = new FormData();
    formData.append(`name`, university);
    if (Object.keys(logo).length) {
      formData.append(`logo`, logo);
    }
    createUniversity(formData);
  };

  return (
    <div className="first-form-container">
      <div className="first-form-inner-container">
        <Input
          label="Type University Name"
          placeholder="University Name"
          value={university}
          onChange={setUniversity}
        />
        <div className="calendar-container">
          <Text strong>{'Upload University Logo'}</Text>
          <Card className="upload-file-container">
            <div style={{ textAlign: 'center' }}>
              <Upload
                disabled={false}
                fileGet={(file) => {
                  console.log(file);
                  setLogo(file);
                }}
              />
            </div>
            {uploadedLogo && <Image width={200} src={uploadedLogo} />}
          </Card>
        </div>
        <div className="first-form-button-container">
          <Button
            loading={loading}
            type="primary"
            style={{
              background: '#70C040',
              borderColor: '#70C040',
              borderRadius: 5,
            }}
            onClick={() => {
              if (isEdit) {
                university === ''
                  ? message.error('University name is required!')
                  : createUni();
              } else {
                university === ''
                  ? message.error('University name is required!')
                  : Object.keys(logo).length === 0
                  ? message.error('Logo  is required!')
                  : createUni();
              }
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

FirstForm.propTypes = {
  createUniversity: PropTypes.func,
  loading: PropTypes.bool,
  updateName: PropTypes.func,
  isEdit: PropTypes.any,
  uploadedLogo: PropTypes.any,
  name: PropTypes.string,
};

export default FirstForm;
