import React, { useState } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { Typography, Button, message } from 'antd';
import PropTypes from 'prop-types';

var Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Recoreder = ({ disabled, fileGet }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [Seconds, setSeconds] = useState(0);
  const [MilliSeconds, setMilliSeconds] = useState(0);
  const [int, setInt] = useState(0);

  const { Text } = Typography;

  const getAudioPermission = () => {
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { audio: true },
        () => {
          setIsBlocked(false);
        },
        () => {
          setIsBlocked(true);
        }
      );
    }
  };

  const start = () => {
    getAudioPermission();
    if (isBlocked) {
      message.error("Permission Denied can't Record audio");
    } else {
      if (!isRecording) {
        Mp3Recorder.start()
          .then(() => {
            var MyInterval = setInterval(changeMilSeconds, 100);
            setInt(MyInterval);
            setIsRecording(true);
          })
          .catch((e) => console.error(e));
      } else {
        stop();
      }
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        clearInterval(int);
        const file = new File(buffer, 'Recorded_audio.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        let src = URL.createObjectURL(file);
        fileGet(file);
        setBlobURL(src);
        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  const changeMilSeconds = () => {
    setMilliSeconds((milSeconds) => {
      if (milSeconds === 90) {
        setSeconds((s) => s + 1);
        return 0;
      } else {
        return milSeconds + 10;
      }
    });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        disabled={disabled}
        type="primary"
        danger
        size="middle"
        style={{
          borderRadius: '6px',
          marginTop: '5%',
          width: '120px',
          height: '36px',
        }}
        onClick={start}
      >
        {isRecording ? 'Stop' : 'Record'}
      </Button>
      <Text
        type="secondary"
        style={{ display: 'block', fontSize: '16px', marginTop: '2%' }}
      >
        {Seconds < 10 ? `0${Seconds}` : `${Seconds}`}/
        {MilliSeconds < 90 ? `0.${MilliSeconds}` : `0.${MilliSeconds}`}
      </Text>
      <audio src={blobURL} controls="controls" />
    </div>
  );
};

Recoreder.propTypes = {
  disabled: PropTypes.bool,
  fileGet: PropTypes.func,
};

export default Recoreder;
