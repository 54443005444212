import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
const AuthCheck = ({ Component, rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (localStorage.getItem('token') !== null) {
          return <Redirect to={{ pathname: '/' }} />;
        } else {
          return <Component />;
        }
      }}
    />
  );
};

AuthCheck.propTypes = {
  rest: PropTypes.any,
  Component: PropTypes.any,
};

export default AuthCheck;
