import React from 'react';
import { connect } from 'react-redux';
import dasboard from '../../assets/dashboard.png';
import Layout from '../Layout';

let Dashboard = () => {
  return (
    <Layout>
      <img src={dasboard} alt="dashboard-img" style={{ width: '100%' }} />
    </Layout>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDisptachToProps = () => {
  return {};
};
export default connect(mapStateToProps, mapDisptachToProps)(Dashboard);
