import { Upload, message, Button } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from '../../../components';
import { DeleteOutlined } from '@ant-design/icons';
import { readString } from 'react-papaparse';

const FourthForm = ({
  students,
  importStudents,
  loading,
  deleteStudent,
  universityCreation,
}) => {
  const columns = [
    {
      title: 'Student Name',
      targetValue: 'first_name',
    },
    {
      title: 'Email',
      targetValue: 'email',
    },
    {
      title: 'Date Created',
      targetValue: 'created_at',
    },
    {
      title: 'Actions',
      targetValue: '',
      render: [
        {
          Title: 'Remove',
          Icon: DeleteOutlined,
          style: {
            fontSize: '16px',
            margin: '0px 3px 0px 3px',
            ':hover': { fontSize: '18px' },
          },
        },
      ],
    },
  ];

  function processData(allText) {
    let csvText = readString(allText);
    let data = [...csvText.data];
    let columNames = data[0];
    console.log(columNames, 'JTJTJTJ');
  }

  const beforeUpload = (file) => {
    const reader = new FileReader();
    const isCsv =
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'document/csv' ||
      file.type === 'text/csv';
    if (!isCsv) {
      message.error('You can only upload CSV file!');
    }
    //I have setted the limit to default that is 2 MBS
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('File must smaller than 2MB!');
    }

    reader.onload = (e) => {
      processData(e.target.result);
    };

    importStudents(file);
    return isCsv && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, fontSize: 10, color: 'gray' }}>
        Click here to upload file
      </div>
    </div>
  );

  const IconClicked = (row, type, dataSource = []) => {
    console.log(dataSource);
    switch (type) {
      case 'Remove':
        {
          deleteStudent(row.id);
        }
        break;
      default:
        console.log('error');
    }
  };

  return (
    <div className="fourth-form-container">
      {students.length ? (
        <>
          <Table
            columns={columns}
            data={students}
            total={students.length}
            perPage={20}
            setCurrentPage={() => {}}
            loading={false}
            IconClicked={IconClicked}
          />
          <div className="fourth-form-button-container">
            <Button
              loading={loading}
              type="primary"
              style={{
                background: '#70C040',
                borderColor: '#70C040',
                borderRadius: 5,
              }}
              onClick={() => universityCreation()}
            >
              Done
            </Button>
          </div>
        </>
      ) : (
        <div className="file-uploader">
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            iconRender={() => <UploadOutlined />}
          >
            {uploadButton}
          </Upload>
        </div>
      )}
    </div>
  );
};

FourthForm.propTypes = {
  importStudents: PropTypes.func,
  students: PropTypes.array,
  loading: PropTypes.bool,
  universityCreation: PropTypes.func,
  deleteStudent: PropTypes.func,
};

export default FourthForm;
