import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Row, Col, Pagination } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { APPROVE_STUDENT, REJECT_STUDENT } from '../../constants';
import StudentsReviewItem from './StudentsReviewItem';

const StudentsReview = ({
  students,
  page,
  total,
  limit,
  setCurrentPage,
  approveStudent,
  rejectStudent,
}) => {
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    setSelection([]);
  }, [students]);

  const handleChangeStudentSelection = (id, checked) => {
    setSelection([
      ...selection.filter((item) => item !== id),
      ...(checked ? [id] : []),
    ]);
  };

  const handleClickSelectAll = () => {
    setSelection(students.map((student) => student.id));
  };

  const handleClickClearAll = () => {
    setSelection([]);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleApproveStudent = (id) => {
    approveStudent(id);
  };

  const handleRejectStudent = (id) => {
    rejectStudent(id);
  };

  const handleApproveStudents = () => {
    for (const id of selection) {
      approveStudent(id);
    }
  };

  const handleRejectStudents = () => {
    for (const id of selection) {
      rejectStudent(id);
    }
  };

  const renderActionButtons = () => (
    <div className="d-flex justify-content-between">
      <div className="w-25 d-flex justify-content-start">
        <Button onClick={handleClickSelectAll}>Select All</Button>
        <Button onClick={handleClickClearAll}>Clear All</Button>
      </div>
      <div className="d-flex">
        <Button
          className="d-flex align-items-center"
          disabled={!selection.length}
          icon={<CheckOutlined style={{ color: '#52c41a' }} />}
          onClick={handleApproveStudents}
        >
          Approve
        </Button>
        <Button
          className="d-flex align-items-center"
          disabled={!selection.length}
          icon={<CloseOutlined style={{ color: '#ff4d4f' }} />}
          onClick={handleRejectStudents}
        >
          Reject
        </Button>
      </div>
      <div className="w-25 d-flex justify-content-end">
        <Pagination
          simple
          current={page}
          onChange={handleChangePage}
          pageSize={limit}
          total={total}
        />
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column">
      {renderActionButtons()}
      <div className="my-3">
        <Row gutter={[24, 24]}>
          {students.map((student) => (
            <Col key={student.id} xs={12} sm={8} md={6}>
              <StudentsReviewItem
                student={student}
                selected={selection.indexOf(student.id) !== -1}
                changeStudentSelection={handleChangeStudentSelection}
                approveStudent={handleApproveStudent}
                rejectStudent={handleRejectStudent}
              />
            </Col>
          ))}
        </Row>
      </div>
      {renderActionButtons()}
    </div>
  );
};

StudentsReview.propTypes = {
  students: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setCurrentPage: PropTypes.func,
  limit: PropTypes.number,
  page: PropTypes.number,
  approveStudent: PropTypes.func,
  rejectStudent: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    studentState: state.studentState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  approveStudent: (payload) => {
    dispatch({
      type: APPROVE_STUDENT,
      payload,
    });
  },
  rejectStudent: (payload) => {
    dispatch({
      type: REJECT_STUDENT,
      payload,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsReview);
