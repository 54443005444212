import { takeLatest, put, call } from 'redux-saga/effects';
import { getUtil, postUtil } from '../../services/GradlineApi';
import {
  getFormFailed,
  getFormInitiate,
  getFormSuccess,
  submitFormFailed,
  submitFormInitiate,
  submitFormSuccess,
} from './actions';
import { FORM_SUBMIT, GET_FORM } from './constants';

export function* getForm({ token }) {
  try {
    yield put(getFormInitiate());
    const url = `/university/student/form?token=${token}`;
    const response = yield call(getUtil, url);
    if (response.status === 200 || response.status === 201) {
      yield put(getFormSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getFormFailed(err));
  }
}

export function* submitForm({ data }) {
  try {
    yield put(submitFormInitiate());
    const url = `/university/student/form-submission`;
    const response = yield call(postUtil, url, data);
    if (response.status === 200 || response.status === 201) {
      yield put(submitFormSuccess(response.data.data));
    }
  } catch (err) {
    yield put(submitFormFailed(err));
  }
}

const completeSagas = [
  takeLatest(GET_FORM, getForm),
  takeLatest(FORM_SUBMIT, submitForm),
];

export const StudentFormSaga = completeSagas;
