import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateStatus,
  formSubmission,
  addField,
  getUniversity,
} from '../University/actions';
import { RESET_STATE } from '../University/constants';
import { StudentForm } from '../../components';
import Layout from '../Layout';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import './styles.css';

const Form = ({
  getUni,
  formRules,
  addField,
  dataLoading,
  loading,
  updateStatusAction,
  formSubmissionAction,
  addFieldLoading,
  selectedUniversityId,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (selectedUniversityId !== null) {
      getUni(selectedUniversityId, () => {});
    } else {
      history.push('/universities');
    }
  }, []);

  return (
    <Layout>
      {dataLoading ? (
        <div className="form-board-container">
          <Spin />
        </div>
      ) : (
        <StudentForm
          isEdit={true}
          formRules={formRules}
          isDisabled
          addField={(data, callBack) => addField(data, callBack)}
          addFieldLoading={addFieldLoading}
          loading={loading}
          updateStatus={updateStatusAction}
          formSubmission={(data) => {
            formSubmissionAction({
              properties: data.properties,
              form_title: data.form_title,
              form_id: data.form.id,
              university_id: data.university_id,
            });
          }}
        />
      )}
    </Layout>
  );
};

Form.propTypes = {
  formRules: PropTypes.object,
  loading: PropTypes.bool,
  dataLoading: PropTypes.bool,
  getUni: PropTypes.func,
  addField: PropTypes.func,
  formSubmissionAction: PropTypes.func,
  updateStatusAction: PropTypes.func,
  addFieldLoading: PropTypes.bool,
  selectedUniversityId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { selectedUniversityId } = state.layoutState;
  const {
    formRules,
    loading,
    addFieldLoading,
    dataLoading,
  } = state.universityState;
  return {
    formRules,
    loading,
    addFieldLoading,
    dataLoading,
    selectedUniversityId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getUni: (id, callBack) => {
    dispatch(getUniversity(id, callBack));
  },
  addField: (data, callback) => {
    dispatch(addField(data, callback));
  },
  formSubmissionAction: (data, callback) => {
    dispatch(formSubmission(data, callback));
  },
  updateStatusAction: (id) => {
    dispatch(updateStatus(id));
  },
  resetState: () => {
    dispatch({
      type: RESET_STATE,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
