import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const CustomDropDown = ({ data, selected, handleSelected }) => {
  const [selectedLocal, setSelected] = useState({});

  useEffect(() => {
    setSelected(data.filter((d) => d.id === selected)[0]);
  }, [selected]);

  const handleMenuClick = (e) => {
    handleSelected(Number(e.key));
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {data.map((i) => (
        <Menu.Item key={i.id}>{i.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>
        {Object.keys(selectedLocal).length
          ? selectedLocal.name
          : 'Select University'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

CustomDropDown.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.number,
  handleSelected: PropTypes.func,
};

export default CustomDropDown;
