import React from 'react';
import '../style.css';
import PropTypes from 'prop-types';
import { StudentForm } from '../../../components';

const FORM_ELEMENT_TYPE = {
  TEXT: 'text',
  VOICE_RECORDING: 'voice_record_type',
};
Object.freeze(FORM_ELEMENT_TYPE);

const SecondForm = ({
  formRules,
  addField,
  addFieldLoading,
  formSubmission,
  loading,
  updateStatus,
  isEdit,
}) => {
  return (
    <StudentForm
      isEdit={isEdit}
      isDisabled
      formRules={formRules}
      addField={(data, callBack) => addField(data, callBack)}
      addFieldLoading={addFieldLoading}
      loading={loading}
      updateStatus={updateStatus}
      formSubmission={(data) => {
        formSubmission(data);
      }}
    />
  );
};

SecondForm.propTypes = {
  formRules: PropTypes.object,
  addField: PropTypes.func,
  addFieldLoading: PropTypes.bool,
  formSubmission: PropTypes.func,
  loading: PropTypes.bool,
  updateStatus: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default SecondForm;
