import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const Uploader = ({ disabled, fileGet }) => {
  const props = {
    beforeUpload: (file) => {
      if (file.type !== 'audio/mpeg') {
        message.error(`${file.name} is not a .mp3`);
      }
      fileGet(file);
      return file.type === 'audio/mpeg' ? true : Upload.LIST_IGNORE;
    },
    onChange: () => {},
  };

  return (
    <Upload {...props} maxCount={1}>
      <Button
        disabled={disabled}
        type="primary"
        danger
        size="middle"
        style={{
          borderRadius: '6px',
          marginTop: '5%',
          width: '120px',
          height: '36px',
        }}
        icon={<UploadOutlined />}
      >
        Upload{' '}
      </Button>
    </Upload>
  );
};

Uploader.propTypes = {
  disabled: PropTypes.string,
  fileGet: PropTypes.func,
};

export default Uploader;
