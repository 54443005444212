import React, { useState } from 'react';
import { Row, Col, Input, Typography, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import authImage from '../../../assets/signup.png';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ResetPasswordAction } from '../actions';

const ResetPassword = ({ ResetPasswordFunc, state }) => {
  const history = useHistory();

  const { Title, Text } = Typography;

  const [passwordTop, setpasswordTop] = useState('');
  const [passwordBottom, setpasswordBottom] = useState('');

  const InputChangePasswordTop = (value) => {
    setpasswordTop(value);
  };
  const InputChangePasswordBottom = (value) => {
    setpasswordBottom(value);
  };

  const a = 5;
  const handlePasswordChange = () => {
    if (
      passwordTop !== passwordBottom &&
      passwordTop !== '' &&
      passwordBottom !== ''
    ) {
      message.error('Both password must match');
      setpasswordTop('');
      setpasswordBottom('');
    } else {
      ResetPasswordFunc({
        token: state.token,
        email: state.email,
        password: passwordTop,
      });
    }
    if (a > 6) {
      history.push('/');
    }
  };

  return (
    <div>
      <Row className="auth-container">
        <Col span={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <img src={logo} className="logo-img" />
            <img src={authImage} className="auth-img" />
          </div>
        </Col>
        <Col span={12} style={{ paddingRight: '5%' }}>
          <Title level={3}>RESET PASSWORD</Title>
          <div className="input-container">
            <Text strong>New Password</Text>
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={passwordTop}
              onChange={(e) => {
                InputChangePasswordTop(e.target.value);
              }}
              style={{
                borderRadius: '5px',
                boxSizing: 'border-box',
                height: '35px',
              }}
            />
          </div>

          <div className="input-container">
            <Text strong>New Password</Text>
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={passwordBottom}
              onChange={(e) => {
                InputChangePasswordBottom(e.target.value);
              }}
              style={{
                borderRadius: '5px',
                boxSizing: 'border-box',
                height: '35px',
              }}
            />
          </div>

          <div>
            <Button
              type="primary"
              size={'20px'}
              style={{
                marginTop: '20px',
              }}
              onClick={handlePasswordChange}
            >
              Change
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ResetPassword.propTypes = {
  state: PropTypes.object,
  ResetPasswordFunc: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    state: state.AuthState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ResetPasswordFunc: (payload) => {
    dispatch(ResetPasswordAction(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
