import React from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import logo from '../../../assets/logo-header.png';
import '../styles.css';

const Header = () => {
  const { Title } = Typography;
  return (
    <div>
      <div className="header-container-student">
        <img src={logo} alt="gradline logo" />
      </div>
      <div className="student-heading-container">
        <div className="student-icon-container">
          <FileDoneOutlined className="student-icon" />
        </div>
        <Title level={3}>Student Registration Form</Title>
      </div>
    </div>
  );
};

export default Header;
