import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Typography, Checkbox, Button } from 'antd';
import CusotmInput from '../../../components/Input';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import authImage from '../../../assets/gradline-auth.png';
import logo from '../../../assets/logo.png';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogRocket from 'logrocket';

const { Text } = Typography;
const LoginForm = ({ login, state }) => {
  const history = useHistory();
  useEffect(() => {
    var name = localStorage.getItem('username');
    if (name !== null && name !== undefined) {
      setUsername(name);
      setCheck(true);
      try {
        if (!process.env.NODE_ENV || process.env.NODE_ENV != 'development') {
          LogRocket.identify(name, {
            role: 'platform-user',
          });
        } else {
          console.log('prod mode');
        }
      } catch (err) {
        console.log('LoginForm:: LogRocket.identify failed with', err);
      }
    }
  }, []);

  useEffect(() => {
    if (state.isLoggedIn === true && state.token !== '') {
      localStorage.setItem('loggedIn', state.isLoggedIn);
      localStorage.setItem('token', state.token);
      history.push('/');
    }
  }, [state]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [check, setCheck] = useState(false);

  const InputChangeUsername = (newUsername) => {
    setUsername(newUsername);
  };

  const InputChangePassword = (newPassword) => {
    setPassword(newPassword);
  };

  const rememberMeClick = (e) => {
    setCheck(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('username', username);
    }
  };

  const HandleForgotPassword = () => {
    history.push('/forgot-password');
  };

  const Login = () => {
    login({ email: username, password: password });
  };

  return (
    <div>
      <Row className="auth-container">
        <Col span={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={logo} className="logo-img" />
            <img src={authImage} className="auth-img" />
          </div>
        </Col>
        <Col span={12} style={{ paddingRight: '5%' }}>
          <CusotmInput
            placeholder="name@domain.com"
            label="Username"
            value={username}
            onChange={InputChangeUsername}
          />

          <div className="input-container">
            <Text strong>Password</Text>
            <Input.Password
              placeholder="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={password}
              onChange={(e) => {
                InputChangePassword(e.target.value);
              }}
              style={{
                borderRadius: '5px',
                boxSizing: 'border-box',
                height: '35px',
              }}
            />
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Checkbox onChange={rememberMeClick} checked={check}>
              Remember Me
            </Checkbox>
            <a
              style={{ textDecoration: 'underline' }}
              onClick={() => HandleForgotPassword()}
            >
              Forgot Password
            </a>
          </div>

          <div>
            <Button
              type="primary"
              size={'20px'}
              style={{
                marginTop: '20px',
              }}
              onClick={Login}
            >
              Log in
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

LoginForm.propTypes = {
  state: PropTypes.object,
  login: PropTypes.func,
};

export default LoginForm;
