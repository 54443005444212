import axios from 'axios';
import { notification } from 'antd';
import { ExceptionMap } from 'antd/lib/result';

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: type.toUpperCase(),
    description: message,
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');
axios.defaults.headers = {
  Authorization: `Bearer ${token}`,
};

const postUtil = (url, data) => axios.post(url, data);

const putUtil = (url, data) =>
  axios({
    method: 'put',
    url,
    data,
  });

const patchUtil = (url, data) =>
  axios({
    method: 'patch',
    url,
    data,
  });

const getUtil = (url, data = null) => axios.get(url, { params: data });

const deleteUtil = (url, data = null) => axios.delete(url, { params: data });

export function setDefault() {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.defaults.headers = headers;
}

export { postUtil, getUtil, putUtil, patchUtil, deleteUtil };

axios.interceptors.response.use(
  (response) => {
    if (response.config.method !== 'get') {
      openNotificationWithIcon('success', response.data.message);
    }

    return response;
  },
  (error) => {
    const { data } = error.response;
    openNotificationWithIcon(
      'error',
      data.message ? data.message : 'Something went wrong!'
    );
    if (error.response.status === 401) {
      localStorage.clear();
    }
    if (
      error.response.status === 401 ||
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 500
    ) {
      throw new ExceptionMap(error);
    }
    throw new ExceptionMap(error);
  }
);
