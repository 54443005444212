import {
  GET_FORM,
  GET_FORM_INITIATE,
  GET_FORM_FAILED,
  GET_FORM_SUCCESS,
  UPDATE_VALUE,
  FORM_SUBMIT,
  FORM_SUBMIT_INITIATE,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAILED,
} from './constants';

//Get Student Form
export function getForm(token) {
  return {
    type: GET_FORM,
    token,
  };
}
export function getFormInitiate() {
  return {
    type: GET_FORM_INITIATE,
  };
}
export function getFormSuccess(data) {
  return {
    type: GET_FORM_SUCCESS,
    data,
  };
}

export function getFormFailed(error) {
  return {
    type: GET_FORM_FAILED,
    error,
  };
}

//Form Submission by Student
export function submitForm(data) {
  return {
    type: FORM_SUBMIT,
    data,
  };
}
export function submitFormInitiate() {
  return {
    type: FORM_SUBMIT_INITIATE,
  };
}
export function submitFormSuccess(data) {
  return {
    type: FORM_SUBMIT_SUCCESS,
    data,
  };
}

export function submitFormFailed(error) {
  return {
    type: FORM_SUBMIT_FAILED,
    error,
  };
}

//Update Value
export function updateValue(id, value) {
  return {
    type: UPDATE_VALUE,
    id,
    value,
  };
}
