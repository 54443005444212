import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard';
import University from '../University';
import Auth from '../Authentication';
import Slides from '../Slide';
import EmailConfirmation from '../Authentication/components/EmailConfirmation';
import ResetPassword from '../Authentication/components/ResetPassword';
import Form from '../Form';
import ProtectedRoute from './ProtectedRoute';
import AuthCheck from './AuthCheck';
import StudentDashboard from '../Students';
import StudentForm from '../StudentForm';

class App extends Component {
  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Switch>
          <AuthCheck exact path="/login" Component={Auth} />
          <AuthCheck path="/forgot-password" Component={EmailConfirmation} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <ProtectedRoute exact path="/" Component={Dashboard} />
          <ProtectedRoute path="/universities" Component={University} />
          <ProtectedRoute path="/university/:id" Component={StudentDashboard} />
          <ProtectedRoute path="/:id/form" Component={Form} />
          <ProtectedRoute path="/:id/slides" Component={Slides} />
          <Route exact path="/form/:token" component={StudentForm} />
        </Switch>
      </div>
    );
  }
}

export default App;
