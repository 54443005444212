import {
  GET_FORM_INITIATE,
  GET_FORM_SUCCESS,
  GET_FORM_FAILED,
  UPDATE_VALUE,
  FORM_SUBMIT_INITIATE,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAILED,
} from './constants';

const initialState = {
  loading: false,
  formLoading: false,
  formRules: {},
  isFormSubmitted: false,
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case GET_FORM_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case GET_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        formRules: {
          ...action.data,
        },
      };
    case GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
      };

    case FORM_SUBMIT_INITIATE:
      return {
        ...state,
        formLoading: true,
      };
    case FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        formLoading: false,
        isFormSubmitted: true,
      };
    case FORM_SUBMIT_FAILED:
      return {
        ...state,
        formLoading: false,
      };

    case UPDATE_VALUE:
      var { formRules } = state;
      var { properties } = formRules;

      var indexFind = properties.findIndex((p) => p.id === action.id);
      var updatedProperty = Object.assign({}, properties[indexFind], {
        value: action.value,
      });
      var updateFormRules = Object.assign({}, formRules, {
        properties: [
          ...state.formRules.properties.slice(0, indexFind),
          updatedProperty,
          ...state.formRules.properties.slice(
            indexFind + 1,
            state.formRules.properties.length
          ),
        ],
      });
      return {
        ...state,
        formRules: { ...updateFormRules },
      };
    default:
      return state;
  }
}
