export const GET_FORM = 'STUDENT@GET_FORM';
export const GET_FORM_INITIATE = 'STUDENT@GET_FORM_INITIATE';
export const GET_FORM_SUCCESS = 'STUDENT@GET_FORM_SUCCESS';
export const GET_FORM_FAILED = 'STUDENT@GET_FORM_FAILED';

export const FORM_SUBMIT = 'STUDENT@FORM_SUBMIT';
export const FORM_SUBMIT_INITIATE = 'STUDENT@FORM_SUBMIT_INITIATE';
export const FORM_SUBMIT_SUCCESS = 'STUDENT@FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_FAILED = 'STUDENT@GET_FORM_FAILED';

export const UPDATE_VALUE = 'STUDENT@UPDATE_VALUE';
