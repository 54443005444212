import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../style.css';
import { Tabs, Card } from 'antd';
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';
import FourthForm from './FourthForm';
import AdminTab from './AdminTab';
const { TabPane } = Tabs;

const UniversityCreationForm = ({
  createUniversity,
  formRules,
  loading,
  addField,
  addFieldLoading,
  formSubmission,
  updateStatus,
  importStudents,
  students,
  universityCreation,
  updateName,
  deleteStudent,
  getTemplates,
  templates,
  selectSlide,
  templateId,
  templateLoading,
  universityAdmins,
  createAdmin,
  adminCreationLoading,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Card className="tab-conatiner">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab.toString()}
        onChange={(key) => setActiveTab(key)}
        style={{ marginBottom: 32 }}
      >
        <TabPane tab="Basic Info" key="1" className="tab-inner-container">
          <FirstForm
            uploadedLogo={formRules.logo}
            name={formRules.name ? formRules.name : ''}
            isEdit={formRules.university_id}
            createUniversity={(data) =>
              formRules.university_id
                ? updateName(data, formRules.university_id, () =>
                    setActiveTab(2)
                  )
                : createUniversity(data, () => setActiveTab(2))
            }
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Admins" key="2" disabled={!Object.keys(formRules).length}>
          <AdminTab
            loading={adminCreationLoading}
            universityAdmins={universityAdmins}
            adminCreationLoading={adminCreationLoading}
            createAdmin={(data, callback) =>
              createAdmin(
                { ...data, university_id: formRules.university_id },
                callback
              )
            }
          />
        </TabPane>
        <TabPane tab="Form" key="3" disabled={!Object.keys(formRules).length}>
          <SecondForm
            formRules={formRules}
            isEdit={formRules.form && formRules.form.id ? true : false}
            addField={(data, callback) => addField(data, callback)}
            addFieldLoading={addFieldLoading}
            loading={loading}
            updateStatus={(id) => updateStatus(id)}
            formSubmission={(data) =>
              formRules.form !== null
                ? formSubmission(
                    { ...data, form_id: formRules.form.id },
                    () => {}
                  )
                : formSubmission(data, () => {})
            }
          />
        </TabPane>
        <TabPane tab="Slide" key="4" disabled={!Object.keys(formRules).length}>
          <ThirdForm
            templates={templates}
            getTemplates={() => getTemplates()}
            onNextTap={() => {
              setActiveTab(4);
            }}
            selectSlide={(id) =>
              selectSlide({
                university_id: formRules.university_id,
                template_id: id,
              })
            }
            templateId={templateId}
            loading={loading}
            templateLoading={templateLoading}
          />
        </TabPane>
        <TabPane
          tab="Student List"
          key="5"
          disabled={!Object.keys(formRules).length}
        >
          <FourthForm
            deleteStudent={(id) => deleteStudent(id)}
            universityCreation={() => universityCreation()}
            students={students}
            loading={loading}
            importStudents={(file) => {
              importStudents(
                { id: formRules.university_id, file: file },
                () => {}
              );
            }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

UniversityCreationForm.propTypes = {
  createUniversity: PropTypes.func,
  formRules: PropTypes.object,
  loading: PropTypes.bool,
  addField: PropTypes.func,
  addFieldLoading: PropTypes.bool,
  formSubmission: PropTypes.func,
  updateStatus: PropTypes.func,
  importStudents: PropTypes.func,
  students: PropTypes.array,
  universityCreation: PropTypes.func,
  updateName: PropTypes.func,
  deleteStudent: PropTypes.func,
  getTemplates: PropTypes.func,
  templates: PropTypes.array,
  selectSlide: PropTypes.func,
  templateId: PropTypes.number,
  templateLoading: PropTypes.bool,
  universityAdmins: PropTypes.array,
  createAdmin: PropTypes.func,
  adminCreationLoading: PropTypes.bool,
};

export default UniversityCreationForm;
