import {
  GET_STUDENT_INFO_INIT,
  GET_STUDENT_INFO_SUCCESS,
  GET_STUDENT_INFO_FAILED,
  SEND_EMAIL_TO_SELECTED_STUDENTS_DONE,
  UPDATE_SELECTED_STUDENTS,
  SEND_EMAIL_TO_SELECTED_STUDENTS,
  GET_STUDNETS_INIT,
  GET_STUDNETS_SUCCESS,
  GET_STUDNETS_FAILED,
  DOWNLOAD_PRESENTATION_INIT,
  DOWNLOAD_PRESENTATION_FAILED,
  DOWNLOAD_PRESENTATION_SUCCESS,
} from './constants';
const initialState = {
  students: [],
  student: {},
  total: 0,
  loading: false,
  selectedStudents: '',
  sendingEmail: false,
  emailSent: false,
  studentloading: false,
  downloadLoading: false,
};

export default function StudentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_INFO_INIT:
      return {
        ...state,
        studentloading: true,
      };
    case GET_STUDENT_INFO_SUCCESS:
      return {
        ...state,
        student: action.data,
        studentloading: false,
      };
    case GET_STUDENT_INFO_FAILED:
      return {
        ...state,
        studentloading: false,
      };
    case GET_STUDNETS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDNETS_SUCCESS:
      return {
        ...state,
        students: [...action.data.data],
        total: action.data.total,
        loading: false,
      };
    case GET_STUDNETS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case DOWNLOAD_PRESENTATION_INIT:
      return {
        ...state,
        downloadLoading: true,
      };
    case DOWNLOAD_PRESENTATION_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
      };
    case DOWNLOAD_PRESENTATION_FAILED:
      return {
        ...state,
        downloadLoading: false,
      };

    case UPDATE_SELECTED_STUDENTS:
      return {
        ...state,
        selectedStudents: action.payload,
      };
    case SEND_EMAIL_TO_SELECTED_STUDENTS:
      return {
        ...state,
        sendingEmail: true,
      };
    case SEND_EMAIL_TO_SELECTED_STUDENTS_DONE:
      return {
        ...state,
        sendingEmail: false,
        emailSent: true,
      };
    default:
      return state;
  }
}
