import { takeLatest, put, call } from 'redux-saga/effects';
import {
  LOGIN,
  LOGINSUCCESS,
  LOGINFAILURE,
  EMAILCONFIRMATION,
  EMAILCONFIRMATIONDONE,
  EMAILCONFIRMATIONFAILED,
  RESETPASSWORD,
  //   RESETPASSWORDSUCCESS,
  //   RESETPASSWORDFAILURE
} from './constants';
import { postUtil } from '../../services/GradlineApi';
import { setDefault } from '../../services/GradlineApi';
export function* userLogin(action) {
  try {
    const url = `/login`;
    const resp = yield call(postUtil, url, {
      email: action.payload.email,
      password: action.payload.password,
    });
    if (!resp.data.error) {
      localStorage.setItem('token', resp.token);
      localStorage.setItem('user', JSON.stringify(resp.data));
      yield put({
        type: LOGINSUCCESS,
        payload: resp.data,
      });
      setDefault();
    } else {
      yield put({
        type: LOGINFAILURE,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* emailConfirm(action) {
  const email = action.payload;
  try {
    const url = `/forget-password`;
    const resp = yield call(postUtil, url, {
      email: email,
    });
    if (resp.data.msg !== null) {
      yield put({
        type: EMAILCONFIRMATIONDONE,
        payload: email,
      });
    } else {
      yield put({
        type: EMAILCONFIRMATIONFAILED,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* resetPassword(action) {
  const { email, token, password } = action.payload;
  const url = `/password/reset?token=${token}&email=${email}&password=${password}`;
  const resp = yield call(postUtil, url);
  console.log(resp);
}

const completeSagas = [
  takeLatest(LOGIN, userLogin),
  takeLatest(EMAILCONFIRMATION, emailConfirm),
  takeLatest(RESETPASSWORD, resetPassword),
];

export const LoginSaga = completeSagas;
