import { takeLatest, call, put } from 'redux-saga/effects';
import {
  APPROVE_STUDENT,
  DOWNLOAD_PRESENTATION,
  GET_STUDENT_INFO,
  GET_STUDNETS,
  SEND_EMAIL_TO_SELECTED_STUDENTS,
  SEND_EMAIL_TO_SELECTED_STUDENTS_DONE,
  REJECT_STUDENT,
} from './constants';
import { getUtil, postUtil } from '../../services/GradlineApi';
import {
  downloadPresentationFailed,
  downloadPresentationInit,
  downloadPresentationSuccess,
  getStudentInfoInit,
  getStudentInfoSuccess,
  getStudentInfoFailed,
  getStudentsFailed,
  getStudentsInit,
  getStudentsSuccess,
} from './actions';

export function* downloadPresentation({ id }) {
  try {
    yield put(downloadPresentationInit());
    const url = `/download-presentation/${id}`;
    const response = yield call(getUtil, url);
    if (response.status === 200 || response.status === 201) {
      window.open(`${response.data.data.presentation_link}`, '_blank');
      yield put(downloadPresentationSuccess());
    }
  } catch (err) {
    yield put(downloadPresentationFailed(err));
  }
}

export function* getStudents({ data }) {
  try {
    yield put(getStudentsInit());
    const url = `/university/${data.id}/students?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    const response = yield call(getUtil, url);
    if (response.status === 200) {
      yield put(getStudentsSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getStudentsFailed(err));
  }
}

export function* getStudentInfo({ id }) {
  try {
    yield put(getStudentInfoInit());
    const url = `/student/${id}`;
    const response = yield call(getUtil, url);
    if (response.status === 200) {
      yield put(getStudentInfoSuccess(response.data.data));
    }
  } catch (err) {
    yield put(getStudentInfoFailed(err));
  }
}

export function* sendEmail({ payload, callback }) {
  const url = '/university/students/email';
  const resp = yield call(postUtil, url, {
    id: payload,
  });
  if (!resp.data.error) {
    callback();
    yield put({
      type: SEND_EMAIL_TO_SELECTED_STUDENTS_DONE,
    });
  }
}

export function* approveStudent({ payload }) {
  try {
    const url = `/student-approval/${payload}`;
    yield call(postUtil, url, { approval_status: 3 });
  } catch (err) {
    console.log(err);
  }
}

export function* rejectStudent({ payload }) {
  try {
    const url = `/student-approval/${payload}`;
    yield call(postUtil, url, { approval_status: 4 });
  } catch (err) {
    console.log(err);
  }
}

const completeSagas = [
  takeLatest(GET_STUDNETS, getStudents),
  takeLatest(DOWNLOAD_PRESENTATION, downloadPresentation),
  takeLatest(GET_STUDENT_INFO, getStudentInfo),
  takeLatest(SEND_EMAIL_TO_SELECTED_STUDENTS, sendEmail),
  takeLatest(APPROVE_STUDENT, approveStudent),
  takeLatest(REJECT_STUDENT, rejectStudent),
];

export const StudentSaga = completeSagas;
